import { CameraIcon } from '../icons/CameraIcon';
import RecordingTimer from './buttons/MediaRecordButton/RecordingTimer';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from '../../helper/classNames';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { WarningCircleIcon } from '../icons/WarningCircleIcon';
import { createPortal } from 'react-dom';
import { Button } from '../common/Button';
import { ModalBody, ModalTitle, ModalWindow } from '../Modal';
import { TextInput } from '../Input';
import {
  addLiveStreamToMeeting,
  confirmMeetingLiveStreamSettings,
  removeLiveStreamFromMeeting,
  updateMeetingLiveStream,
} from '../../helper/api';
import { shallowEqual, useSelector } from 'react-redux';
import { notifyUser } from '../authoring/hooks';
import { LoadingSpinner } from '../LoadingSpinner';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { ArrowRightIcon } from '../icons/ArrowRightIcon';
import { unionBy } from 'lodash';
import { useDelayedFnCallWithCancel } from '../../hooks/useDelayedFnCallWithCancel';
import { episodeTypes } from 'zync-common/types';

export const getStartEpisodeLabel = (episodeType) => {
  switch (episodeType) {
    case episodeTypes.solo: {
      return 'Session';
    }
    default: {
      return 'Episode';
    }
  }
};

const MainStreamsModalContent = ({
  meetingId,
  removedStreamId,
  isLoading,
  setIsLoading,
  isSettingUp,
  setIsStreamsFormModalOpen,
  streams,
  setRemovedStreamId,
  setStreamConfigurationSelected,
  onCancel,
  handleSetRejectedStreamConfigLocally,
}) => {
  if (isSettingUp && isLoading) {
    return (
      <div className="flex w-full items-center flex-col">
        <h2 className="text-lg font-semibold mb-3 -mt-3">Setup in progress</h2>
        <LoadingSpinner />
      </div>
    );
  }

  if (!isSettingUp) {
    return (
      <div className="-mt-4">
        <p className="text-blue-gray text-sm">
          You have to go to your streaming endpoint and{' '}
          <strong className="font-medium">"Go Live"</strong> from there first.
        </p>
        <img
          className="w-full block my-4"
          src={getStaticAssetUrl('golivetip.svg')}
          alt="Go Live"
        />
        <p className="text-blue-gray text-sm mb-3">
          Your viewers will see a{' '}
          <strong className="font-medium">
            'Waiting for the show to start'
          </strong>{' '}
          message until you initiate the episode by pressing{' '}
          <strong className="font-medium">'Start Episode'</strong>.
        </p>
        <Button
          color={Button.colors.PURPLE}
          onClick={onCancel}
          size={Button.sizes.FULL}
        >
          <span className="text-sm font-medium">Done</span>
        </Button>
      </div>
    );
  }

  return (
    <>
      {isSettingUp && (
        <button
          color={Button.colors.PURPLE}
          onClick={() => setIsStreamsFormModalOpen(true)}
          className="-mt-6 mb-6 block"
        >
          <span className="text-xs font-medium underline text-blue">
            Add new stream configuration
          </span>
        </button>
      )}
      {streams.length ? (
        <ul className="max-h-[400px] overflow-auto">
          {streams.map((stream) => (
            <li
              key={stream.streamId}
              className="text-blue-gray border-b border-blue-gray/25 mb-3 group"
            >
              <p className="overflow-hidden p-1 text-sm font-medium text-blue-dark">
                {stream.name}
              </p>
              <div className="flex justify-between w-full">
                <p className="overflow-hidden p-1 text-sm text-blue-gray pb-2">
                  {stream.streamUrl}
                </p>
                <div className="flex gap-2 justify-end opacity-0 group-hover:opacity-100">
                  {stream.streamId === removedStreamId ? (
                    <LoadingSpinner width="12px" thickness="2px" />
                  ) : (
                    <button
                      onClick={async () => {
                        setRemovedStreamId(stream.streamId);

                        try {
                          if (stream.simulcastTargetId) {
                            await removeLiveStreamFromMeeting(
                              meetingId,
                              stream.streamId
                            );
                          } else {
                            handleSetRejectedStreamConfigLocally(
                              stream.streamId
                            );
                          }
                        } catch (error) {
                          notifyUser(
                            'We could not remove the stream. Please try again'
                          );
                        } finally {
                          setRemovedStreamId(null);
                        }
                      }}
                    >
                      <span className="text-xxs text-blue underline">
                        Delete
                      </span>
                    </button>
                  )}

                  <button
                    onClick={async () => setStreamConfigurationSelected(stream)}
                  >
                    <span className="text-xxs text-blue underline">Edit</span>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
      {!streams.length ? (
        <p className="flex items-center gap-2">
          <div
            aria-hidden="false"
            role="img"
            aria-label="Warning Icon"
            className="w-5 h-5 grid place-content-center text-sm border-2 border-red rounded-full p-1 text-red font-medium"
          >
            !
          </div>{' '}
          <span className="text-sm">No Live Stream configured</span>
        </p>
      ) : null}
      {isSettingUp ? (
        <div className="text-center flex w-full justify-center mt-4">
          <Button
            color={Button.colors.PURPLE}
            state={
              isLoading
                ? Button.states.LOADING
                : !isSettingUp
                ? Button.states.DISABLED
                : Button.states.DEFAULT
            }
            onClick={async () => {
              setIsLoading(true);

              const uninitializedStreams = streams.filter(
                (stream) => !stream.simulcastTargetId
              );

              Promise.all(
                uninitializedStreams.map((stream) =>
                  addLiveStreamToMeeting(meetingId, stream)
                )
              )
                .then(async (results) => {
                  const success = results.every((result) => result.success);
                  if (success) {
                    await confirmMeetingLiveStreamSettings(meetingId);
                  } else {
                    notifyUser(
                      'One of your stream configurations was incorrect and we could not set up live stream. Please verify your configuration and make sure Stream URL and Stream Key are correct'
                    );
                    setIsLoading(false);
                  }
                })
                .catch(() => {
                  notifyUser(
                    'We could not start recording or streaming. Either one of your stream configurations was incorrect, or there was a server problem'
                  );
                  setIsLoading(false);
                });
            }}
            size={Button.sizes.FULL}
          >
            <span className="text-sm font-medium">
              {streams.length > 0 ? 'Start Streaming' : 'Start Recording'}
            </span>
          </Button>
        </div>
      ) : null}
      {streams.length ? (
        <p className="text-orange-light text-xs mt-3">
          Note: You will not be live to your audience until you hit Start
          Episode
        </p>
      ) : null}
    </>
  );
};
const StreamsFormModal = ({ onCancel, meetingId, initialValues }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    if (!meetingId) {
      return;
    }
    setIsLoading(true);

    try {
      const data = Object.fromEntries(new FormData(event.target).entries());

      if (data.streamId && data.simulcastTargetId) {
        await updateMeetingLiveStream(meetingId, data);
      } else {
        const { success } = await addLiveStreamToMeeting(meetingId, data);

        if (!success) {
          notifyUser(
            'We could not add your stream configuration. Are you sure you included correct Stream URL and Stream Key?'
          );
        }
      }

      setIsLoading(false);
      onCancel();
    } catch (error) {
      notifyUser(
        'We could not add your stream configuration. Please try again'
      );
      setIsLoading(false);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmitForm}>
        <input
          type="hidden"
          name="streamId"
          defaultValue={initialValues?.streamId}
        />
        <input
          type="hidden"
          name="simulcastTargetId"
          defaultValue={initialValues?.simulcastTargetId}
        />
        <TextInput
          label="Name"
          name="name"
          required
          defaultValue={initialValues?.name}
        />
        <TextInput
          label="Stream URL"
          name="streamUrl"
          required
          defaultValue={initialValues?.streamUrl}
          disabled={initialValues?.simulcastTargetId && !!initialValues}
        />
        <TextInput
          label="Stream Key"
          name="streamKey"
          required
          defaultValue={initialValues?.streamKey}
          disabled={initialValues?.simulcastTargetId && !!initialValues}
        />
        <div
          className="flex justify-end gap-2 mt-4"
          style={{
            pointerEvents: isLoading ? 'none' : 'initial',
            opacity: isLoading ? 0.5 : 1,
          }}
        >
          <Button
            type="submit"
            color={Button.colors.PURPLE}
            padding={Button.padding.MEDIUM}
            state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
            size={Button.sizes.FULL}
          >
            <span className="text-sm font-medium">Save</span>
          </Button>
        </div>
      </form>
    </>
  );
};

const rejectWorkspaceStreams = (
  meetingStateStreams = [],
  workspaceStreams = [],
  rejectedStreamIds
) => {
  return unionBy(
    meetingStateStreams,
    workspaceStreams,
    (stream) => stream.streamId
  ).filter((stream) => !rejectedStreamIds.includes(stream.streamId));
};
const StreamsModal = ({
  onCancel,
  isRecording,
  isStreaming,
  recordingStartTime,
  workspaceStreams,
}) => {
  const [isStreamsFormModalOpen, setIsStreamsFormModalOpen] = useState(false);
  const [streamConfigurationSelected, setStreamConfigurationSelected] =
    useState(null);
  const [removedStreamId, setRemovedStreamId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectedStreamConfigs, setRejectedStreamConfigs] = useState([]);

  const meetingId = useSelector((state) => state.meetingState.meetingId);

  const cleanupFormModalState = useCallback(() => {
    setIsStreamsFormModalOpen(false);
    setStreamConfigurationSelected(null);
  }, []);

  // Used only for default workspace stream config, since simulcastTargetId is not initialized
  const handleSetRejectedStreamConfigLocally = (streamId) =>
    setRejectedStreamConfigs((configs) => configs.concat(streamId));

  const isSettingUp = (isStreaming || isRecording) && !recordingStartTime;

  useEffect(() => {
    if (!isSettingUp) {
      setIsLoading(false);
    }
  }, [isSettingUp]);

  const streams =
    useSelector(
      (state) => state.meetingState.state?.liveStreams,
      shallowEqual
    ) || [];

  const allStreams = rejectWorkspaceStreams(
    streams,
    workspaceStreams,
    rejectedStreamConfigs
  );

  const modalTitle = !isSettingUp
    ? 'Your stream is active'
    : isStreamsFormModalOpen
    ? 'Add new stream configuration'
    : streamConfigurationSelected
    ? 'Editing configuration'
    : 'Stream Configurations';

  const backArrow =
    isStreamsFormModalOpen || streamConfigurationSelected ? (
      <button onClick={cleanupFormModalState}>
        <ArrowRightIcon className="fill-blue-gray rotate-180" />
      </button>
    ) : null;

  return (
    <>
      <ModalWindow
        onCancel={onCancel}
        size="md"
        showCancel={!isLoading || !isSettingUp}
      >
        {isLoading || (isLoading && isSettingUp) ? null : (
          <ModalTitle>
            {backArrow} {modalTitle}
          </ModalTitle>
        )}
        <ModalBody>
          {!isStreamsFormModalOpen && !streamConfigurationSelected ? (
            <MainStreamsModalContent
              isSettingUp={isSettingUp}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              removedStreamId={removedStreamId}
              streams={allStreams}
              meetingId={meetingId}
              setIsStreamsFormModalOpen={setIsStreamsFormModalOpen}
              setRemovedStreamId={setRemovedStreamId}
              setStreamConfigurationSelected={setStreamConfigurationSelected}
              onCancel={onCancel}
              handleSetRejectedStreamConfigLocally={
                handleSetRejectedStreamConfigLocally
              }
            />
          ) : (
            <StreamsFormModal
              meetingId={meetingId}
              onCancel={cleanupFormModalState}
              initialValues={streamConfigurationSelected}
            />
          )}
        </ModalBody>
      </ModalWindow>
    </>
  );
};

export const StartEpisodeCountdownModal = React.memo(
  ({ handleStartEpisode, onCancel, episodeType }) => {
    const { call, cancel, countdown } = useDelayedFnCallWithCancel(5);

    useEffect(() => {
      call(handleStartEpisode, onCancel);
    }, [call, onCancel, handleStartEpisode]);

    return (
      <ModalWindow showCancel={false}>
        <div className="bg-blue-dark fixed inset-6 rounded-xl grid place-content-center ">
          <div className="flex flex-col items-center justify-center gap-6">
            <h2 className="font-semibold text-white text-[30px]">
              {getStartEpisodeLabel(episodeType)} Starting In
            </h2>
            <div
              className="p-0.5 rounded-lg w-[473px] h-[237px]"
              style={{
                background:
                  'radial-gradient(80.38% 222.5% at -13.75% -12.36%, #066DE8 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgba(135, 38, 183, 0) 100%)',
              }}
            >
              <div className="bg-blue-dark rounded-lg h-full">
                <div
                  className="flex flex-col items-center justify-center gap-2 rounded-lg h-full"
                  style={{
                    background:
                      'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%)',
                  }}
                >
                  <div>
                    <div className="text-[96px] text-white font-semibold">
                      {countdown}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <Button
                color={Button.colors.RED}
                onClick={() => {
                  cancel();
                  onCancel();
                }}
              >
                <span className="text-sm">
                  Cancel start {getStartEpisodeLabel(episodeType)}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </ModalWindow>
    );
  }
);

const StartStudioButton = ({
  isPreview,
  onGoLive,
  isRecording,
  isStreaming,
  recordingStartTime,
  isLiveStreaming,
  liveStreamName,
  liveStreamModeOn,
  episodeType,
  userDevicesPrecheck,
}) => {
  const isSettingUp = (isStreaming || isRecording) && !recordingStartTime;
  const [isCountdownModalShown, setIsCountdownModalShown] = useState(false);

  const handleStartEpisode = useCallback(() => {
    if (isPreview || episodeType === episodeTypes.solo) {
      onGoLive();
    } else {
      const areLocalDevicesOn = !userDevicesPrecheck || userDevicesPrecheck();

      if (!areLocalDevicesOn) {
        return notifyUser('You must turn your camera and microphone on');
      }

      setIsCountdownModalShown(true);
    }
  }, [episodeType, userDevicesPrecheck, isPreview, onGoLive]);

  const handleCancelStartEpisode = useCallback(() => {
    setIsCountdownModalShown(false);
  }, []);

  return (
    <>
      {isCountdownModalShown ? (
        <StartEpisodeCountdownModal
          handleStartEpisode={onGoLive}
          onCancel={handleCancelStartEpisode}
          episodeType={episodeType}
        />
      ) : null}
      <div className={classNames('flex gap-2 items-center relative pr-4')}>
        {isSettingUp && !liveStreamModeOn && (
          <div className="flex gap-4 items-center justify-between">
            <div className="h-[36px] w-[36px] p-2 bg-blue-gray shrink-0 rounded-full">
              <img
                alt=""
                src={getStaticAssetUrl('loading-studio.gif')}
                className="h-full scale-100"
              />
            </div>
            <p className="whitespace-nowrap text-blue-dark font-bold text-sm pr-3">
              Setting up studio
            </p>
          </div>
        )}
        {isSettingUp && liveStreamModeOn && (
          <div className="h-[50px] grid place-content-center -ml-2 mt-[2px]">
            <Button
              color={Button.colors.GRAY}
              state={Button.states.DISABLED}
              padding={Button.padding.NONE}
            >
              <span className="text-sm font-medium py-2 px-3">
                Start {getStartEpisodeLabel(episodeType)}
              </span>
            </Button>
          </div>
        )}
        {!isSettingUp && isLiveStreaming && !liveStreamModeOn && (
          <p className="font-medium text-xxs">
            Ready to stream to <strong>{liveStreamName}</strong>
          </p>
        )}
        {!isSettingUp && (
          <button
            onClick={handleStartEpisode}
            className={classNames(
              'px-4 py-2 rounded-md cursor-pointer w-fit text-sm font-jakarta font-extrabold bg-purple text-white',
              isSettingUp && 'invisible'
            )}
          >
            <div>
              {isPreview
                ? 'Start Rehearsal'
                : 'Start ' + getStartEpisodeLabel(episodeType)}
            </div>
          </button>
        )}
      </div>
    </>
  );
};
export const OnGoLiveBar = ({
  isLive,
  wentLive,
  isPreview,
  onGoLive,
  onGoOffline,
  recordingStartTime,
  isRecording,
  isStreaming,
  isLiveStreaming,
  primaryColor,
  primaryContrast,
  wentLiveTimeStamp,
  liveStreamName,
  workspacePlan,
  liveStreamModeOn,
  workspaceStreams,
  isSettingUp,
  episodeType,
  userDevicesPrecheck,
}) => {
  const [isLiveEndPopupOpen, setIsLiveEndPopupOpen] = useState(false);
  const [isStreamModalOpen, setIsStreamModalOpen] = useState(false);

  const doc = document.getElementById('header-container');

  const isSolo = episodeType === episodeTypes.solo;

  if (!doc) {
    return null;
  }

  if (isSolo) {
    return null;
  }

  return (
    <>
      {createPortal(
        <>
          <div
            id="banner-header"
            className="bg-white flex justify-between h-[48px] ml-auto"
          >
            <p className="flex items-center gap-2 pl-4">
              <img alt="" src={getStaticAssetUrl('spot-lights-icon.png')} />
              <span className="text-base font-bold uppercase">
                {isPreview ? 'Rehearsal studio' : 'Live Studio'}
              </span>{' '}
            </p>
            <div className="flex gap-4">
              {(workspacePlan === PLANS.admin.id ||
                workspacePlan === PLANS.business.id) &&
              liveStreamModeOn &&
              !isPreview &&
              isSettingUp ? (
                <div className="h-[50px] grid place-content-center">
                  <Button
                    color={Button.colors.PURPLE}
                    onClick={() => setIsStreamModalOpen((wasOpen) => !wasOpen)}
                    padding={Button.padding.NONE}
                  >
                    <span className="text-sm font-medium py-2 px-3">
                      Setup streaming
                    </span>
                  </Button>
                </div>
              ) : null}
              {!isLive && !wentLive && (
                <StartStudioButton
                  isPreview={isPreview}
                  onGoLive={onGoLive}
                  isLive={isLive}
                  isStreaming={isStreaming}
                  isRecording={isRecording}
                  recordingStartTime={recordingStartTime}
                  primaryColor={primaryColor}
                  primaryContrast={primaryContrast}
                  isLiveStreaming={isLiveStreaming}
                  liveStreamName={liveStreamName}
                  liveStreamModeOn={liveStreamModeOn}
                  episodeType={episodeType}
                  userDevicesPrecheck={userDevicesPrecheck}
                />
              )}
              {isLive && !isSolo && !isLiveEndPopupOpen && (
                <div className="flex rounded-full p-1 text-red items-center gap-3 pr-4">
                  {isPreview ? (
                    <div className="flex items-center gap-6">
                      {isPreview && (
                        <p className="whitespace-nowrap rounded-b-xl text-blue-dark text-xs uppercase font-semibold">
                          Rehearsal is not recorded
                        </p>
                      )}
                      <div className="rounded-full p-2 w-fit relative">
                        <div className="absolute inset-0 opacity-10 rounded-full bg-purple"></div>
                        <div className="bg-red w-[80%] h-[2px] rotate-45 absolute translate-y-[10px] -translate-x-[5px]" />
                        <CameraIcon className="fill-purple" />
                      </div>
                    </div>
                  ) : (
                    <div className="rounded-full p-2 w-fit flex items-center justify-center relative">
                      <div className="absolute w-10 h-10 bg-red/10 rounded-full" />
                      <div className="absolute w-6 h-6 bg-transparent border border-1 border-red rounded-full" />
                      <div className="w-4 h-4 bg-red animate-[pulse_2s_infinite] rounded-full" />
                    </div>
                  )}
                  {isPreview ? null : (
                    <RecordingTimer
                      recordingStartTime={wentLiveTimeStamp}
                      labelClassName="text-blue-dark font-semibold relative text-xs block min-w-[55px] h-full flex gap-2 items-center whitespace-nowrap"
                      timerClassName="text-blue-dark font-semibold whitespace-nowrap min-w-[55px] text-sm text-center"
                      label=""
                    />
                  )}
                  <button
                    onClick={
                      isPreview
                        ? onGoOffline
                        : () => setIsLiveEndPopupOpen(true)
                    }
                    className="px-4 py-2 rounded-md cursor-pointer w-[145px] text-sm font-extrabold font-jakarta text-white bg-purple"
                  >
                    End
                  </button>
                </div>
              )}
              {isLive && isLiveEndPopupOpen && (
                <div className="flex gap-3 items-center p-1 pr-4">
                  <p className="text-sm text-blue-dark font-semibold flex items-center gap-1">
                    <WarningCircleIcon />
                    Episode cannot be restarted
                  </p>
                  <button
                    onClick={() => setIsLiveEndPopupOpen(false)}
                    className="bg-gray/25 h-full text-blue-dark px-3 py-1.5 font-jakarta font-extrabold text-sm rounded-md w-[145px]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      onGoOffline();
                      setIsLiveEndPopupOpen(false);
                    }}
                    className="bg-red h-full text-white px-3 py-1.5 font-jakarta font-extrabold text-sm rounded-md w-[145px]"
                  >
                    End
                  </button>
                </div>
              )}
              {!isLive && wentLive && !isSolo && (
                <p className="flex items-center text-blue-dark font-jakarta font-extrabold px-3 py-3 text-sm pr-4">
                  {isPreview ? 'Rehearsal Completed' : 'Episode Completed'}
                </p>
              )}
            </div>
          </div>
        </>,
        doc
      )}
      {isStreamModalOpen ? (
        <StreamsModal
          isStreaming={isStreaming}
          isRecording={isRecording}
          recordingStartTime={recordingStartTime}
          onCancel={() => setIsStreamModalOpen(false)}
          workspaceStreams={workspaceStreams}
        />
      ) : null}
    </>
  );
};
