import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUserWorkspaces } from './useUserWorkspaces';
import { userPersonas as personasMap } from '../helper/categories';
import { exponentialFetchRetry } from '../helper/exponentialFetchRetry';
import { useClientWorkspaceAccess } from './useClientWorkspaceAccess';
import { getTrialDateUntil } from 'zync-common/zyncCustomerPlans';
import { episodeTypes } from 'zync-common/types';
import { WorkspaceContext } from '../App';
import { useWorkspacePlan } from './useWorkspacePlan';

export const fetchUserSeriesApi = async (
  workspaceId,
  emailAddress,
  workspaceType
) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      let query = `/api/meetingseries?emailAddress=${encodeURIComponent(
        emailAddress
      )}&type=${workspaceType === 'solo' ? 'workspace' : 'moderator'}`;
      if (workspaceId) {
        query += `&workspaceId=${encodeURIComponent(workspaceId)}`;
      }
      const response = await fetch(query, {});
      return response;
    },
    jsonResponseHandler: async (response) => {
      const json = await response.json();
      if (json.error) {
        return { error: json.error, result: [] };
      }
      return json.result;
    },
    attributesToLog: {
      userId: emailAddress,
      url: `/api/meetingseries?emailAddress=${encodeURIComponent(
        emailAddress
      )}&type=${
        workspaceType === 'solo' ? 'workspace' : 'moderator'
      }&workspaceId=${encodeURIComponent(workspaceId || '')}`,
    },
  });
};

export const isInCurrentMonth = (date) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const inputMonth = date.getMonth();
  const inputYear = date.getFullYear();

  return inputMonth === currentMonth && inputYear === currentYear;
};

export const usePortal = () => {
  const { workspace } = useContext(WorkspaceContext);
  const history = useHistory();
  const { authenticated, user } = useSelector((_st) => _st.auth);
  const [authMode, setAuthMode] = useState();
  const [redirectToLanding, setRedirectToLanding] = useState(false);
  const { emailAddress, consents } = user || {};
  const { acceptedPrivacyPolicy = false } = consents || {};
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(
    !acceptedPrivacyPolicy
  );

  const [seriesListLoading, setSeriesListLoading] = useState(false);
  const [seriesList, setSeriesList] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const {
    brandKit,
    show,
    currentWorkspaceId: workspaceId,
    isWorkspaceOnboardingRequired,
    isWorkspaceAdmin,
    isWorkspaceSuperAdmin,
    isWorkspaceHiddenAdmin,
    currentWorkspace,
    currentWorkspaceName,
    hostName,
    workspacePlanValidUntil,
    creatorUserName,
    creatorUserId,
    isFirstWorkspaceSolo,
  } = useUserWorkspaces();

  const { isTrial, plan, currentPeriodEnd } = useWorkspacePlan();

  useClientWorkspaceAccess();

  const currentSubscriptionRenewalTime = currentPeriodEnd || 0;

  const navigateToGallery = useCallback(() => {
    history.push('/templates?workspaceId=' + workspaceId);
  }, [history, workspaceId]);

  const workspaceType = workspace?.workspacePlanCache?.workspaceType;
  const fetchNewMeetings = useCallback(
    async (doNotDisplayLoadingSpinner = false) => {
      if (!emailAddress) return;
      // Ensure that a workspace was selected before loading meetings.
      if (!workspaceId) return;
      !doNotDisplayLoadingSpinner && setSeriesListLoading(true);
      let result =
        (await fetchUserSeriesApi(workspaceId, emailAddress, workspaceType)) ||
        [];

      if (Array.isArray(result)) {
        setSeriesList(result);

        const someEpisodesArePlaceholders = result.some(
          (series) => series.settings.episodeType === episodeTypes.placeholder
        );

        if (someEpisodesArePlaceholders) {
          const doNotDisplayLoadingSpinner = true;
          setTimeout(() => fetchNewMeetings(doNotDisplayLoadingSpinner), 5_000);
        }
      }

      setSeriesListLoading(false);
    },
    [workspaceType, workspaceId, emailAddress]
  );

  useEffect(() => {
    if (authenticated) {
      setAuthMode(null);
      fetchNewMeetings();
    } else setAuthMode('signin');
  }, [authenticated, fetchNewMeetings, workspaceId]);

  useEffect(() => {
    const { personas } = user;

    let userCategories = [];
    personas.forEach((persona) => {
      for (const [key, value] of Object.entries(personasMap)) {
        const { templateCategories } = value;
        if (key === persona) {
          userCategories = [...userCategories, ...templateCategories];
        }
      }
    });
  }, [user]);

  const isAdmin = isWorkspaceAdmin || isWorkspaceSuperAdmin;

  return {
    authMode,
    authenticated,
    isWorkspaceOnboardingRequired,
    isFirstWorkspaceSolo,
    redirectToLanding,
    seriesList,
    seriesListLoading,
    setSeriesListLoading,
    setRedirectToLanding,
    user,
    navigateToGallery,
    selectedTemplate,
    setSelectedTemplate,
    isAdmin,
    currentWorkspaceName,
    hostName,
    brandKit,
    workspaceId,
    isTrial,
    plan,
    show,
    soloSettings: workspace?.soloSettings || {},
    trialUntil: getTrialDateUntil(currentWorkspace),
    workspacePlanValidUntil,
    currentSubscriptionRenewalTime,
    showPrivacyPolicyModal,
    setShowPrivacyPolicyModal,
    workspacePlan: currentWorkspace?.workspacePlan,
    creatorUserName,
    creatorUserId,
    fetchNewMeetings,
    requiresOnboarding:
      currentWorkspace?.requiresOnboarding && !isWorkspaceHiddenAdmin,
  };
};
