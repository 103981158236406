import { useUserWorkspaces } from './useUserWorkspaces';
import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClickOutside } from '../helper/useClickOutside';
import { useSelectDefaultWorkspace } from './useSelectDefaultWorkspace';
import mixpanel from 'mixpanel-browser';
import { shallowEqual, useSelector } from 'react-redux';
import { isSoloPlan } from '../helper/isSoloPlan';
import { useWorkspacePlan } from './useWorkspacePlan';

export const useWorkspaceSelector = ({ onAfterSelect = () => {} } = {}) => {
  const {
    currentWorkspaceName,
    currentWorkspaceId,
    userWorkspaces,
    switchWorkspace,
    currentWorkspace,
  } = useUserWorkspaces();
  const { user } = useSelector((_st) => _st.auth, shallowEqual);
  const { userId } = user || {};

  const workspaceMenuRef = useRef(null);

  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
    useState(false);

  const handleDropdownMenuClick = () => {
    setIsExpanded((wasExpanded) => !wasExpanded);
  };

  const { plan, workspaceType } = useWorkspacePlan();

  const selectWorkspace = useCallback(
    async (workspace, userWorkspaces) => {
      const { workspaceId } = workspace;

      mixpanel.track('Menu: Workspace Shifted', {
        distinct_id: userId,
        workspaceId: workspaceId,
      });

      onAfterSelect();
      await switchWorkspace(workspaceId, userWorkspaces, true);
      setIsExpanded(false);
      const isSoloWorkspace = isSoloPlan(plan) || workspaceType === 'solo';
      const urlToRedirect = isSoloWorkspace
        ? '/solo?workspaceId=' + workspaceId
        : '/portal?workspaceId=' + workspaceId;
      history.push(urlToRedirect);
    },
    [userId, onAfterSelect, switchWorkspace, plan, workspaceType, history]
  );

  const handleWorkspaceCreated = useCallback(
    (workspace) => {
      setShowCreateWorkspaceModal(false);
      setIsExpanded(false);
      window.location.reload(false);
      onAfterSelect();
    },
    [onAfterSelect, setShowCreateWorkspaceModal, setIsExpanded]
  );

  useClickOutside(workspaceMenuRef, () => {
    setIsExpanded(false);
    onAfterSelect();
  });

  /** used for side effect for selecting default workspace if there is no workspace selected yet */
  useSelectDefaultWorkspace(currentWorkspaceId);

  return {
    currentWorkspaceId,
    isExpanded,
    showCreateWorkspaceModal,
    setShowCreateWorkspaceModal,
    handleDropdownMenuClick,
    handleWorkspaceCreated,
    selectWorkspace,
    currentWorkspaceName,
    userWorkspaces,
    workspaceMenuRef,
    currentWorkspace,
  };
};
