export const useWorkspaceFlags = (workspace = {}) => {
  const {
    featureFlags: {
      enablePublicContentKit = false,
      useSegments = true,
      useVectorStore = false,
      useDownloadLocalRecording = false,
      useMoreThemes = false,
      useSoloStarterPlan = false,
      useGreenScreen = false,
      useHdResolution = false,
    } = {},
  } = workspace || {};

  return {
    enablePublicContentKit,
    useSegments,
    useVectorStore,
    useDownloadLocalRecording,
    useMoreThemes,
    useSoloStarterPlan,
    useGreenScreen,
    useHdResolution,
  };
};
