import React from 'react';
import { CONTENT_KIT_STATES } from './PublishMenus';
import { Menu } from './PublishV2';
import { makeRandomId } from '../../../helper';
import Masonry from 'react-masonry-css';
import './MenuStyles.css';
import { useSelector } from 'react-redux';
import { isZyncStaff } from '../../../helper/isZyncStaff';

export const ProcessingContentKitView = ({ menus, meetingSeriesId }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);
  return (
    <div className="flex flex-col justify-center items-center py-[60px]">
      <div className="text-blue-dark text-2xl font-semibold text-center mb-4">
        Your content kit is getting ready...
      </div>
      <div className="text-blue-gray text-sm font-medium text-center mb-4">
        Your fully edited video, webpage, social posts, thumbnails, and more are
        just minutes away!
      </div>
      <div className="flex gap-1 mb-10">
        <span className="text-light-gray text-xs">
          This typically takes 10-15 min.
        </span>
        <button
          className="text-purple decoration-purple underline text-xs"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh Now
        </button>
      </div>
      <Masonry
        breakpointCols={2}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {menus.map((m) => (
          <Menu
            menu={m}
            key={`menu-${makeRandomId(6)}`}
            contentKitState={CONTENT_KIT_STATES.processing}
          />
        ))}
      </Masonry>
      {isZyncStaff(userId || '') && (
        <a
          href={`https://contentkit.zync.ai/api/processor/statustext?meetingSeriesId=${meetingSeriesId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer"
        >
          Content kit status
        </a>
      )}
    </div>
  );
};
