import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { CameraIcon } from './icons/CameraIcon';
import { CameraIconSlash } from './icons/CameraIconSlash';
import { MeetingControlMic } from './icons/MeetingControlMic';
import { MeetingControlMicSlash } from './icons/MeetingControlMicSlash';
import {
  Modal,
  ModalWindow,
  ModalTitle,
  ModalBody,
  ModalButton,
} from './Modal';
import { DropdownSelect } from './common/Dropdown';
import { UserVideo } from './UserVideo';
import { logerror, loginfo, logwarn } from '../helper/contextualLogger';
import { useDailyControls } from '../hooks/useDailyControls';
import { useDailyMicVolume } from '../hooks/useDailyMicVolume';
import { LoadingSpinner } from './LoadingSpinner';
import { useElementSize } from '../hooks/useElementSize';
import UIfx from '../blocks/helper/UIFx';
import classNames from '../helper/classNames';
import { TROUBLESHOOTING_URL } from '../helper/constants';
import { AngleIcon } from './icons/AngleIcon';
import mixpanel from 'mixpanel-browser';
import { useGrantMediaPermissions } from '../hooks/useGrantMediaPermissions';
import { useUserMediaPermissions } from '../hooks/useUserMediaPermissions';
import { PermissionsDeniedModal } from './PermissionsDeniedModal';
import { UnableToUseDevicesModal } from './UnableToAccessDevicesModal';
import { DevicesNotFoundModal } from './DevicesNotFoundModal';
import { DevicesAlreadyInUseModal } from './DevicesAlreadyInUseModal';
import { OtherDeviceErrorModal } from './OtherDeviceErrorModal';
import fiveDotsLoading from './assets/lottie/fiveDotsLoading.json';
import Lottie from 'react-lottie';
import { useAreLocalTracksReady } from '../hooks/useAreLocalTracksReady';
import { FuturisticBackground } from './FuturisticBackground';
import { Nudger } from './Nudger';
import { isMobile } from '../helper';
import { BACKGROUND_TYPE_IMAGE } from '../hooks/dailyConstants';
import { Switch } from './common/Switch';

const audioOutputCheck = new UIfx('https://cdn.zync.ai/assets/audio/bell.mp3', {
  volume: 0.2,
  throttleMs: 500,
});

const ParticipantModeMessaging =
  'You are in participant mode. This means you can participate in interactive activities, but your mic and camera will be off by default';

const VolumeMeter = ({ currentVolume }) => {
  return (
    <img
      src={getStaticAssetUrl(
        currentVolume > 0
          ? 'mic-activity-animation.gif'
          : 'mic-activity-still.svg'
      )}
      alt="microphone volume"
      className="h-8 w-8 object-cover absolute top-2 right-2 z-10 rounded"
    />
  );
};

const ErrorMessage = ({ device }) => {
  return (
    <div className="text-red text-xs">
      Your {device} device is not working. Please see troubleshooting guide
      below.
    </div>
  );
};

const fiveDotsLoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: fiveDotsLoading,
};

const StatusModal = ({ label }) => {
  return (
    <ModalWindow
      bg="TRANSPARENT"
      size={Modal.size.md}
      zyncLogo={true}
      boxShadow="3xl"
    >
      <ModalBody>
        <div className="flex flex-col h-full w-full justify-center items-center">
          <div className="w-64">
            <Lottie options={fiveDotsLoadingOptions} />
          </div>
          <div className="text-blue-dark font-semibold text-xl ">{label}</div>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

const CheckingPermissions = () => <StatusModal label="Fetching Devices" />;

const PermissionsPromptRequired = () => (
  <ModalWindow size={Modal.size.md} zyncLogo={true}>
    <ModalBody>
      <div className="flex flex-col h-full w-full justify-center items-center gap-5">
        <img
          src={getStaticAssetUrl('permissions-example.svg')}
          alt="permissions example"
          className="w-[300px]"
        />
        <div className="text-blue-dark text-xl text-center font-semibold">
          Please grant camera & mic permissions
        </div>
        <div className="text-blue-gray text-sm text-center">
          Zync needs access to your camera and microphone so that other
          participants can see and hear you. Zync will ask you to confirm this
          decision on each browser and computer you use.
        </div>
      </div>
    </ModalBody>
  </ModalWindow>
);

var audioCheckTimeout;

const MEDIA_SETTINGS_MODAL_PADDING_PX = 80;

let audioRetries = 0;
let videoRetries = 0;
export const DailyMediaSettings = ({
  type = 'setup',
  onCancel,
  onJoin,
  subscriberOnly = false,
  accentColor,
  useGreenScreen,
}) => {
  /**
   * Add these functions back when background blur is ready.
   * setBackgroundBlur,
   * setBackgroundNone,
   * backgroundType,
   */
  const {
    daily,
    error,
    localTrackState,
    localCameraOn,
    localMicOn,
    toggleCamera,
    toggleMic,
    destroy,
    isLocalCameraUpdating,
    isLocalMicUpdating,
    updateLocalParticipantToPublisher,
    setBackgroundImage,
    //setBackgroundBlur,
    setBackgroundNone,
    backgroundType,
  } = useDailyControls();

  const { user: registeredUser } = useSelector((_st) => _st.auth);
  const modalContext = useSelector((state) => state.clientDetails.modalContext);
  const outputDeviceId = useSelector((_st) => _st.callState.outputDeviceId);
  const { userId, userName } = registeredUser || {};
  const [hasAnsweredPermissionsPrompt, setHasAnsweredPermissionsPrompt] =
    useState(false);
  const [volume, setVolume] = useState(0);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [audioInputDeviceId, setAudioInputDeviceId] = useState();
  const [videoDeviceId, setVideoDeviceId] = useState();
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState(
    outputDeviceId ? outputDeviceId : ''
  );
  const [audioInputDeviceAccessFailure, setAudioInputDeviceAccessFailure] =
    useState(false);
  const [videoDeviceAccessFailure, setVideoDeviceAccessFailure] =
    useState(false);
  const [showTroubleshoot, setShowTroubleshoot] = useState(false);
  const [audioCheckPlaying, setAudioCheckPlaying] = useState(false);
  const [reloadDevicesAnimation, setReloadDevicesAnimation] = useState(false);
  const [audioSettingsError, setAudioSettingsError] = useState(false);
  const [videoSettingsError, setVideoSettingsError] = useState(false);
  const audioPersistentTrack =
    localTrackState?.audioTrackState?.persistentTrack;
  const videoPersistentTrack =
    localTrackState?.videoTrackState?.persistentTrack;

  const {
    isLocalAudioTrackReady,
    isLocalVideoTrackReady,
    allLocalTracksReady,
  } = useAreLocalTracksReady();

  const {
    width: userVideoContainerWidth,
    ref: userVideoContainerRef,
    height: userVideoContainerHeight,
  } = useElementSize();
  const dispatch = useDispatch();

  const {
    areDevicePermissionsDenied,
    areDevicesNotFound,
    areDevicesUsedElsewhere,
    didOtherErrorHappen,
    isCheckingPermissions,
  } = useUserMediaPermissions(setHasAnsweredPermissionsPrompt, subscriberOnly);

  const [isPermissionsDeniedModalShown, setIsPermissionsDeniedModalShown] =
    useState(false);
  const [isDevicesNotFoundModalShown, setDevicesNotFoundModalShown] =
    useState(false);
  const [
    isDevicesUsedElsewhereModalShown,
    setIsDevicesUsedElsewhereModalShown,
  ] = useState(false);
  const [isOtherDevicesErrorModalShown, setOtherDevicesErrorModalShown] =
    useState(false);

  const wasDeclinedFirst = useRef(false);

  useEffect(() => {
    if (areDevicePermissionsDenied) {
      setIsPermissionsDeniedModalShown(true);

      if (!wasDeclinedFirst) {
        wasDeclinedFirst.current = true;
        mixpanel.track('Studio - Media Permissions Declined', {
          distinct_id: userId,
        });
      }

      logwarn({
        message: `"Permissions denied" modal shown for ${userId}`,
        userId,
      });
    }
  }, [areDevicePermissionsDenied, userId]);

  const arePermissionsGranted =
    !areDevicePermissionsDenied &&
    !areDevicesNotFound &&
    !areDevicesUsedElsewhere &&
    !didOtherErrorHappen &&
    !isCheckingPermissions;

  useEffect(() => {
    if (arePermissionsGranted) {
      mixpanel.track('Studio: Media Permissions Granted', {
        distinct_id: userId,
      });
    }
  }, [arePermissionsGranted, userId]);

  useEffect(() => {
    if (areDevicesNotFound) {
      setDevicesNotFoundModalShown(true);
      logwarn({
        message: `"Devices not found" modal shown for ${userId}`,
        userId,
      });
    }
  }, [areDevicesNotFound, userId]);

  useEffect(() => {
    if (areDevicesUsedElsewhere) {
      setIsDevicesUsedElsewhereModalShown(true);
      logwarn({
        message: `"Devices used elsewhere" modal shown for ${userId}`,
        userId,
      });
    }
  }, [areDevicesUsedElsewhere, userId]);

  useEffect(() => {
    if (allLocalTracksReady) {
      setIsDevicesUsedElsewhereModalShown(false);
    }
  }, [allLocalTracksReady]);

  useEffect(() => {
    if (didOtherErrorHappen) {
      setOtherDevicesErrorModalShown(true);
      logwarn({
        message: `"Generic devices error" modal shown for ${userId}`,
        userId,
      });
    }
  }, [didOtherErrorHappen, userId]);

  useGrantMediaPermissions(
    updateLocalParticipantToPublisher,
    audioPersistentTrack,
    videoPersistentTrack,
    setCameraStarted
  );

  const isDailyInitialized = !!daily;

  useEffect(() => {
    const audioValidSettingsErrorTimeout = setTimeout(() => {
      if (
        !subscriberOnly &&
        !isLocalAudioTrackReady &&
        hasAnsweredPermissionsPrompt &&
        isDailyInitialized
      ) {
        setAudioSettingsError(true);
        logwarn({
          userId,
          message: 'Audio Track was not found for user ' + userId,
        });
      }
    }, 20_000);

    return () => clearTimeout(audioValidSettingsErrorTimeout);
  }, [
    isLocalAudioTrackReady,
    hasAnsweredPermissionsPrompt,
    userId,
    isDailyInitialized,
    subscriberOnly,
  ]);

  useEffect(() => {
    const videoValidSettingsErrorTimeout = setTimeout(() => {
      if (
        !subscriberOnly &&
        !isLocalVideoTrackReady &&
        hasAnsweredPermissionsPrompt &&
        isDailyInitialized
      ) {
        setVideoSettingsError(true);
        logerror({
          userId,
          message: 'Video Track was not found for user ' + userId,
        });
      }
    }, 20_000);

    return () => clearTimeout(videoValidSettingsErrorTimeout);
  }, [
    isLocalVideoTrackReady,
    hasAnsweredPermissionsPrompt,
    userId,
    isDailyInitialized,
    subscriberOnly,
  ]);

  const onVolumeChange = useCallback(
    (volume) => {
      if (!localMicOn) return 0;
      const val = Math.floor((volume + 85) / 10);
      setVolume(val);
    },
    [localMicOn]
  );

  const { stopMicVolumeTrack } = useDailyMicVolume(onVolumeChange);

  // Removes duplicates in an array of devices
  const filterDeviceByDeviceId = (devices) => {
    const filteredDevicesMap = {};
    devices.forEach((device) => {
      if (!filteredDevicesMap[device.deviceId]) {
        filteredDevicesMap[device.deviceId] = device;
      } else {
        if (device.label.includes('Default')) {
          filteredDevicesMap[device.deviceId] = device;
        }
      }
    });
    return Object.values(filteredDevicesMap);
  };

  // Populates state with the currently available media devices.
  const fetchMediaDevices = useCallback(async () => {
    if (!daily) return {};
    const { devices } = await daily.enumerateDevices();

    // Note that when a user blocks media access, it produces a device that has no label.
    // We filter these devices out so that they cannot be chosen in the dropdowns.
    const audioInputDevices = filterDeviceByDeviceId(
      devices.filter((d) => d.kind === 'audioinput' && d.deviceId && d.label)
    );

    const videoDevices = filterDeviceByDeviceId(
      devices.filter((d) => d.kind === 'videoinput' && d.deviceId && d.label)
    );

    const audioOutputDevices = filterDeviceByDeviceId(
      devices.filter((d) => d.kind === 'audiooutput' && d.deviceId && d.label)
    );

    setVideoDevices(videoDevices);

    loginfo({
      userId,
      message: `Video Devices for userId: ${userId}: ${JSON.stringify(
        videoDevices
      )}`,
    });

    setAudioInputDevices(audioInputDevices);
    loginfo({
      userId,
      message: `Audio Input Devices for userId: ${userId}: ${JSON.stringify(
        audioInputDevices
      )}`,
    });

    setAudioOutputDevices(audioOutputDevices);
    loginfo({
      userId,
      message: `Audio Output Devices for userId: ${userId}: ${JSON.stringify(
        audioOutputDevices
      )}`,
    });

    const MAX_RETRIES = 3;

    if (videoDevices.length === 0) {
      if (videoRetries === MAX_RETRIES) {
        videoRetries += 1;
        return fetchMediaDevices();
      } else {
        if (!isCheckingPermissions && !areDevicePermissionsDenied) {
          mixpanel.track(
            'Studio - Media Settings: User could not load any video input devices',
            {
              distinct_id: userId,
            }
          );
          logwarn({
            message: 'User could not load any video input devices',
            userId,
          });
        }
        setVideoDeviceAccessFailure(true);
      }
    }

    if (audioInputDevices.length === 0) {
      if (audioRetries === MAX_RETRIES) {
        audioRetries += 1;
        return fetchMediaDevices();
      } else {
        if (!isCheckingPermissions && !areDevicePermissionsDenied) {
          mixpanel.track(
            'Studio - Media Settings: User could not load any audio input devices',
            {
              distinct_id: userId,
            }
          );
          logwarn({
            message: 'User could not load any audio input devices',
            userId,
          });
        }
        setAudioInputDeviceAccessFailure(true);
      }
    }

    return {
      audioInputDevices,
      videoDevices,
      audioOutputDevices,
    };
  }, [areDevicePermissionsDenied, daily, isCheckingPermissions, userId]);

  // Populates error strings.
  useEffect(() => {
    // Intentionally ignore 'devices error', which occurs when their are no video inputs available.
    if (!error || error === 'devices error') return;
    setVideoDeviceAccessFailure(true);
    setAudioInputDeviceAccessFailure(true);
  }, [error]);

  // Force startCamera to ask user to grant permissions for mic and camera if not done.
  useEffect(() => {
    if (!daily) return;
    const getUserPermissions = async () => {
      if (type === 'setup') {
        await daily.startCamera();
      }
      // Mark the camera started regardless.
      if (modalContext !== 'PERMISSIONS') {
        setCameraStarted(true);
      }
    };
    getUserPermissions();
  }, [type, daily, modalContext]);

  // Get all available devices and any existing devices on load to start the audio and video
  // with the right initial settings
  useEffect(() => {
    if (!daily || !cameraStarted) return;
    const selectInitialDevices = async () => {
      const [currentlySelectedDevices, { audioInputDevices, videoDevices }] =
        await Promise.all([daily.getInputDevices(), fetchMediaDevices()]);
      const initialAudioInputDeviceId =
        currentlySelectedDevices.mic.deviceId || audioInputDevices[0]?.deviceId;
      setAudioInputDeviceId(initialAudioInputDeviceId);
      const initialVideoDeviceId =
        currentlySelectedDevices.camera.deviceId || videoDevices[0]?.deviceId;
      setVideoDeviceId(initialVideoDeviceId);
    };
    selectInitialDevices();
  }, [fetchMediaDevices, daily, cameraStarted]);

  // Whenever selected device ids change, create new DailyTrackStates.
  useEffect(() => {
    if (subscriberOnly || !(videoDeviceId && audioInputDeviceId)) return;

    const updateTrackStates = async () => {
      const devices = {
        videoSource: videoDeviceId,
        audioSource: audioInputDeviceId,
      };
      const currentDevices = await daily.getInputDevices();
      if (
        currentDevices.mic.deviceId !== audioInputDeviceId ||
        currentDevices.camera.deviceId !== videoDeviceId
      ) {
        if (cameraStarted) {
          loginfo({
            userId,
            message: `Updating selected input devices ${JSON.stringify(
              devices
            )}`,
          });
          // Kill volume tracking before switching devices, or two mics will be activated at once for a render cycle,
          // which blows up some versions of Firefox.
          stopMicVolumeTrack();
          await daily.setInputDevicesAsync(devices);
        }
      }
    };
    updateTrackStates();
  }, [
    daily,
    cameraStarted,
    subscriberOnly,
    audioInputDeviceId,
    videoDeviceId,
    type,
    userId,
    stopMicVolumeTrack,
  ]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_OUTPUT_DEVICE_ID',
      outputDeviceId: audioOutputDeviceId,
    });
  }, [audioOutputDeviceId, dispatch]);

  const onClickJoinButton = useCallback(() => {
    mixpanel.track(
      areDevicePermissionsDenied
        ? 'Studio: Enter without media permission'
        : 'Studio: User Clicked Join Meeting',
      {
        distinct_id: userId,
      }
    );

    if (onJoin) onJoin();
  }, [onJoin, areDevicePermissionsDenied, userId]);

  const onCancelImpl = useCallback(() => {
    if (type === 'setup') {
      destroy();
    }
    if (onCancel) {
      onCancel();
    }
  }, [destroy, type, onCancel]);

  useEffect(() => {
    if (videoDeviceAccessFailure || audioInputDeviceAccessFailure) {
      setShowTroubleshoot(true);
    }
  }, [videoDeviceAccessFailure, audioInputDeviceAccessFailure]);

  useEffect(() => {
    if (audioInputDevices.length > 0 && audioInputDeviceAccessFailure)
      setAudioInputDeviceAccessFailure(false);
  }, [audioInputDevices, audioInputDeviceAccessFailure]);

  useEffect(() => {
    if (videoDevices.length > 0 && videoDeviceAccessFailure)
      setVideoDeviceAccessFailure(false);
  }, [videoDevices, videoDeviceAccessFailure]);

  useEffect(() => {
    if (hasAnsweredPermissionsPrompt && !isCheckingPermissions) {
      mixpanel.track('Studio: Media Settings - Media Settings Loaded', {
        distinct_id: userId,
      });
    }
  }, [userId, isCheckingPermissions, hasAnsweredPermissionsPrompt]);

  useEffect(() => {
    if (!localMicOn) {
      mixpanel.track('Studio: Media Settings - Microphone Muted', {
        distinct_id: userId,
      });
    }
  }, [userId, localMicOn]);

  useEffect(() => {
    if (!localCameraOn) {
      mixpanel.track('Studio: Media Settings - Camera Turned Off', {
        distinct_id: userId,
      });
    }
  }, [userId, localCameraOn]);

  if (type === 'setup' && isCheckingPermissions) {
    return (
      <>
        <FuturisticBackground /> <CheckingPermissions />
      </>
    );
  }

  if (type === 'setup' && !hasAnsweredPermissionsPrompt) {
    return <PermissionsPromptRequired />;
  }

  return (
    <>
      <ModalWindow
        size={Modal.size.xxl}
        bg={type === 'setup' ? 'TRANSPARENT' : 'FADE'}
        onCancel={() => {
          mixpanel.track('Studio: Media Settings - Closed with X click', {
            distinct_id: userId,
          });
          onCancelImpl();
        }}
        shrinkOnResize={!isMobile}
        width={
          isMobile
            ? undefined
            : window.screen.width - MEDIA_SETTINGS_MODAL_PADDING_PX
        }
        height={
          isMobile
            ? undefined
            : window.screen.height - MEDIA_SETTINGS_MODAL_PADDING_PX
        }
      >
        <div
          className={`flex flex-col w-full ${
            isMobile ? '' : 'h-full'
          } shadow-lg rounded-md`}
        >
          <ModalTitle fontColor={ModalTitle.fontColor.PURPLE}>
            {type === 'setup' ? 'Get ready for your show' : 'Media Settings'}
          </ModalTitle>
          {/*Negative margin here because flex items' margins do not collapse, in this case Title's and Body's*/}
          <div className={`flex flex-col -my-8`}>
            <ModalBody>
              <div
                className={`flex gap-7 w-full ${
                  isMobile ? 'flex-col' : 'grow h-full'
                }`}
              >
                <div
                  className={`flex relative ${
                    isMobile ? 'justify-center w-full' : 'w-8/12'
                  }`}
                  ref={userVideoContainerRef}
                >
                  <UserVideo
                    width={userVideoContainerWidth}
                    height={userVideoContainerHeight}
                    userId={userId}
                    userName={userName}
                    trackStates={localTrackState}
                    accentColor={accentColor}
                  >
                    <VolumeMeter currentVolume={volume} />
                  </UserVideo>
                </div>
                <div className={`${isMobile ? 'w-full' : 'w-4/12'}`}>
                  <div className="flex 2xl:h-16 h-12 w-full 2xl:mb-10 mb-5">
                    {type === 'setup' ? (
                      <Nudger>
                        <ModalButton
                          handleOnClick={onClickJoinButton}
                          buttonText="Join"
                          fontSize={ModalButton.fontSize.xl}
                        />
                      </Nudger>
                    ) : (
                      <ModalButton
                        handleOnClick={onCancelImpl}
                        buttonText="Close"
                        fontSize={ModalButton.fontSize.xl}
                      />
                    )}
                  </div>
                  <div
                    className={classNames(
                      `flex-col 2xl:gap-7 gap-3 ${
                        isMobile ? 'hidden' : 'flex'
                      }`,
                      subscriberOnly && 'opacity-30 pointer-events-none'
                    )}
                  >
                    <div className="text-blue-gray text-xl font-medium">
                      Set up your devices
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-blue-dark text-base">
                        Microphone
                      </div>
                      <DropdownSelect
                        options={audioInputDevices.map((d) => ({
                          key: d.deviceId,
                          title: d.label.replace(/\(\w+:\w+\)/, ''),
                        }))}
                        emptyOptionsMessage="No microphone detected"
                        selectedKey={audioInputDeviceId}
                        onSelect={(value) => {
                          mixpanel.track(
                            'Studio: Media Settings - Mic Changed',
                            {
                              distinct_id: userId,
                              deviceId: value,
                            }
                          );
                          setAudioInputDeviceId(value);
                        }}
                        error={audioInputDeviceAccessFailure}
                        ellipsis={false}
                      />
                      {audioInputDeviceAccessFailure && (
                        <ErrorMessage device={'audio input'} />
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-blue-dark text-base">
                        Camera
                      </div>
                      <DropdownSelect
                        options={videoDevices.map((d) => ({
                          key: d.deviceId,
                          title: d.label.replace(/\(\w+:\w+\)/, ''),
                        }))}
                        emptyOptionsMessage="No camera detected"
                        selectedKey={videoDeviceId}
                        onSelect={(value) => {
                          mixpanel.track(
                            'Studio: Media Settings - Camera Changed',
                            {
                              distinct_id: userId,
                              deviceId: value,
                            }
                          );
                          setVideoDeviceId(value);
                        }}
                        error={videoDeviceAccessFailure}
                        ellipsis={false}
                      />
                      {videoDeviceAccessFailure && (
                        <ErrorMessage device={'video'} />
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-blue-dark text-base">
                        Speakers
                      </div>
                      <DropdownSelect
                        options={[
                          { key: null, title: 'System default' },
                          ...audioOutputDevices.map((d) => ({
                            key: d.deviceId,
                            title: d.label.replace(/\(\w+:\w+\)/, ''),
                          })),
                        ]}
                        emptyOptionsMessage="No speakers"
                        selectedKey={audioOutputDeviceId}
                        onSelect={(value) => {
                          mixpanel.track(
                            'Studio: Media Settings - Speakers Changed',
                            {
                              distinct_id: userId,
                              deviceId: value,
                            }
                          );
                          setAudioOutputDeviceId(value);
                        }}
                        ellipsis={false}
                      />
                    </div>
                    {/*<div className="flex justify-between mb-3">
                      <b>Background blur</b>
                      <Switch
                        type="background blur"
                        checked={backgroundType === BACKGROUND_TYPE_BLUR}
                        onChange={async () => {
                          if (!localCameraOn) return;
                          if (backgroundType === BACKGROUND_TYPE_BLUR) {
                            mixpanel.track(
                              'Studio: Media Settings - Background Blur Changed',
                              { distinct_id: userId, enabled: false }
                            );
                            setBackgroundNone();
                          } else {
                            mixpanel.track(
                              'Studio: Media Settings - Background Blur Changed',
                              { distinct_id: userId, enabled: true }
                            );
                            setBackgroundBlur();
                          }
                        }}
                        disabled={!localCameraOn}
                      />
                    </div>*/}
                    {useGreenScreen ? (
                      <div className="flex justify-between mb-3">
                        <b>Green Screen</b>
                        <Switch
                          type="background greenscreen"
                          checked={backgroundType === BACKGROUND_TYPE_IMAGE}
                          onChange={async () => {
                            if (!localCameraOn) return;
                            if (backgroundType === BACKGROUND_TYPE_IMAGE) {
                              mixpanel.track(
                                'Studio: Media Settings - Background Image (greenscreen) Changed',
                                { distinct_id: userId, enabled: false }
                              );
                              setBackgroundNone();
                            } else {
                              mixpanel.track(
                                'Studio: Media Settings - Background Image (greenscreen) Changed',
                                { distinct_id: userId, enabled: true }
                              );
                              setBackgroundImage(
                                getStaticAssetUrl('greenscreen.png')
                              );
                            }
                          }}
                          disabled={!localCameraOn}
                        />
                      </div>
                    ) : null}
                    <div className="flex flex-col w-full">
                      <div className="flex flex-row items-center w-full justify-between relative">
                        <label
                          className="text-base text-blue-dark font-semibold cursor-pointer grow"
                          htmlFor="troubleshoot"
                        >
                          Troubleshoot
                        </label>
                        <button
                          className={classNames(
                            'w-3 flex justify-center items-center bg-white border-0 cursor-pointer absolute right-[15.5px]',
                            showTroubleshoot ? 'rotate-90' : 'rotate-[270deg]'
                          )}
                          onClick={() => {
                            setShowTroubleshoot((prevShowTroubleshoot) => {
                              if (!prevShowTroubleshoot) {
                                mixpanel.track(
                                  'Studio: Media Settings - Troubleshoot Expanded',
                                  { distinct_id: userId }
                                );
                              }
                              return !prevShowTroubleshoot;
                            });
                          }}
                          id="troubleshoot"
                        >
                          <AngleIcon width="12px" />
                        </button>
                      </div>
                      {showTroubleshoot && (
                        <>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={TROUBLESHOOTING_URL}
                            className="text-sm text-blue max-w-fit 2xl:mt-5 mt-1"
                          >
                            Troubleshooting guide
                          </a>
                          <div className="flex flex-row gap-5 2xl:mt-5 mt-3">
                            <button
                              className={classNames(
                                'bg-white cursor-pointer px-4 py-2 gap-2 flex justify-center items-center h-fit',
                                '2xl:text-sm text-xs text-purple font-medium',
                                'rounded border-solid border-purple border-2',
                                audioCheckPlaying && 'bg-purple text-white'
                              )}
                              onClick={() => {
                                mixpanel.track(
                                  'Studio: Media Settings - Test Audio Clicked',
                                  {
                                    distinct_id: userId,
                                  }
                                );
                                if (audioCheckPlaying) {
                                  setAudioCheckPlaying(false);
                                  clearTimeout(audioCheckTimeout);
                                  audioOutputCheck.stop();
                                } else {
                                  setAudioCheckPlaying(true);
                                  audioCheckTimeout = setTimeout(() => {
                                    setAudioCheckPlaying(false);
                                  }, 8000);
                                  audioOutputCheck.play({ outputDeviceId });
                                }
                              }}
                            >
                              <img
                                src={getStaticAssetUrl(
                                  audioCheckPlaying
                                    ? 'mic-activity-animation.gif'
                                    : 'purple-music-note.svg'
                                )}
                                alt="play sound"
                                className="2xl:h-4 h-3"
                              />
                              {audioCheckPlaying ? 'Stop audio' : 'Test audio'}
                            </button>
                            <button
                              className={classNames(
                                'bg-white cursor-pointer px-4 py-2 gap-2 flex justify-center items-center',
                                '2xl:text-sm text-xs text-purple font-medium',
                                'rounded border-solid border-purple border-2'
                              )}
                              onClick={() => {
                                setReloadDevicesAnimation(true);
                                mixpanel.track(
                                  'Studio: Media Settings - Reload Devices Clicked',
                                  {
                                    distinct_id: userId,
                                  }
                                );
                                fetchMediaDevices();
                                setTimeout(() => {
                                  setReloadDevicesAnimation(false);
                                }, 1000);
                              }}
                              disabled={reloadDevicesAnimation}
                            >
                              <img
                                src={getStaticAssetUrl('purple-refresh.svg')}
                                alt="reload devices"
                                className={classNames(
                                  '2xl:h-4 h-3',
                                  reloadDevicesAnimation && 'animate-spin'
                                )}
                              />
                              <span className="min-w-max">Reload devices</span>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    {subscriberOnly && (
                      <div className="flex flex-col text-cyan">
                        {ParticipantModeMessaging}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ModalBody>
          </div>
          <div className="lg:h-[69px] w-full flex px-8 items-center gap-7 justify-center lg:mt-0 mt-5">
            <button
              className="h-12 w-12 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
              title="Toggle Microphone"
              disabled={
                !toggleMic || audioInputDeviceAccessFailure || subscriberOnly
              }
              onClick={() => {
                mixpanel.track('Studio: Media Settings - Toggle Mic', {
                  distinct_id: userId,
                  on: localMicOn,
                });
                toggleMic();
              }}
            >
              {isLocalMicUpdating ? (
                <div className="animate-[fadeIn_100ms]">
                  <LoadingSpinner width="40px" />
                </div>
              ) : localMicOn ? (
                <MeetingControlMic />
              ) : (
                <MeetingControlMicSlash />
              )}
            </button>
            <button
              className="h-12 w-12 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
              title="Toggle Camera"
              disabled={
                !toggleCamera || videoDeviceAccessFailure || subscriberOnly
              }
              onClick={() => {
                mixpanel.track('Studio: Media Settings - Toggle Camera', {
                  distinct_id: userId,
                  on: localCameraOn,
                });
                toggleCamera();
              }}
            >
              {isLocalCameraUpdating ? (
                <LoadingSpinner width="40px" />
              ) : localCameraOn ? (
                <CameraIcon />
              ) : (
                <CameraIconSlash />
              )}
            </button>
          </div>
        </div>
      </ModalWindow>
      {audioSettingsError || videoSettingsError ? (
        <UnableToUseDevicesModal
          onDismiss={() => {
            setAudioSettingsError(false);
            setVideoSettingsError(false);
          }}
          isCameraReady={isLocalVideoTrackReady}
          isMicReady={isLocalAudioTrackReady}
        />
      ) : (
        isPermissionsDeniedModalShown && (
          <PermissionsDeniedModal
            onDismiss={() => setIsPermissionsDeniedModalShown(false)}
          />
        )
      )}
      {isDevicesUsedElsewhereModalShown && (
        <DevicesAlreadyInUseModal
          onDismiss={() => setIsDevicesUsedElsewhereModalShown(false)}
        />
      )}
      {isDevicesNotFoundModalShown && (
        <DevicesNotFoundModal
          onDismiss={() => setDevicesNotFoundModalShown(false)}
        />
      )}
      {isOtherDevicesErrorModalShown && (
        <OtherDeviceErrorModal
          onDismiss={() => setOtherDevicesErrorModalShown(false)}
        />
      )}
    </>
  );
};

export default DailyMediaSettings;
