import { useCallback, useEffect, useState } from 'react';
import {
  fetchUserApi,
  getCreatorWorkspaces,
  getOrganizationApi,
} from '../../helper/api';
import { useParams } from 'react-router-dom';
import { isZyncStaff } from '../../helper/isZyncStaff';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const useOrganizationPage = () => {
  const userId = useSelector((st) => st.auth?.user?.userId);

  const { organizationId } = useParams();
  const [organization, setOrganization] = useState(null);
  const [workspaces, setWorkspaces] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        const data = await getOrganizationApi(organizationId);
        setOrganization(data);
      } finally {
        setLoading(false);
      }
    };
    initialize();
  }, [organizationId]);

  const ownerEmail = organization?.ownerId;
  useEffect(() => {
    const getWorkspaces = async () => {
      if (!ownerEmail) return;
      const data = await getCreatorWorkspaces(ownerEmail);
      setWorkspaces(data);
    };
    getWorkspaces();
  }, [ownerEmail]);

  const handleCreateWorkspace = useCallback(async () => {
    try {
      const user = await fetchUserApi(organization.ownerId);

      if (!user) {
        return;
      }

      await axios.post(`/api/quick-start-workspace`, {
        workspace: {
          workspaceName: `Trial Workspace`,
          logoUrl: organization.orgLogoUrl,
        },
        speaker: {
          email: organization.ownerId,
          name: user.userName,
          contentPillars: [],
          headline: user.bio,
        },
        topic: {
          recentArticle: '',
        },
      });

      const data = await getCreatorWorkspaces(ownerEmail);
      setWorkspaces(data);
    } catch (error) {
    } finally {
    }
  }, [organization, ownerEmail]);

  const authorized = organization?.ownerId === userId || isZyncStaff(userId);

  return {
    organization,
    workspaces,
    authorized,
    loading,
    handleCreateWorkspace,
  };
};
