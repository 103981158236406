import React, { useEffect, useState } from 'react';
import { presenterTypes } from '../../../components/series/settings/EventPresenters';
import { Button } from '../../../components/common/Button';
import { sendHostInvite, sendSpeakerInvite } from '../../../helper/api';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Buffer } from 'buffer';
import { CopyIcon } from '../../../components/icons/CopyIcon';
import { toast } from 'react-toastify';
import { CompletedButtonLabel } from './PlanScheduleCard';
import { TaskCardHeader } from './Plan';
import { episodeTypes } from 'zync-common/types';

const convertJSONToBase64 = (data) => {
  const b64string = Buffer.from(JSON.stringify(data)).toString('base64');
  return b64string
    .replaceAll('+', '.')
    .replaceAll('/', '_')
    .replaceAll('=', '-');
};

const INVITE_URL = (
  meetingSeriesId,
  base64EncodedUserData,
  withRouter = false
) =>
  `${
    withRouter ? '' : window.location.origin
  }/e/${meetingSeriesId}/join?q=${base64EncodedUserData}`;

const getHostInviteUrl = (host, meetingSeriesId) => {
  if (!host?.emailAddress) {
    return null;
  }
  const { user: hostUser } = host || {};
  const base64EncodedUserData = convertJSONToBase64({
    u: hostUser?.userName || host.fullName,
    e: hostUser?.emailAddress || host.emailAddress,
  });

  return INVITE_URL(meetingSeriesId, base64EncodedUserData);
};

export const getSpeakerInviteUrl = (
  speaker,
  meetingSeriesId,
  withRouter = false
) => {
  if (!speaker?.fullName && !speaker?.userName) {
    return null;
  }

  if (!speaker?.emailAddress) {
    return null;
  }

  const base64EncodedUserData = convertJSONToBase64({
    u: speaker.fullName || speaker.userName,
    e: speaker.emailAddress,
  });

  return INVITE_URL(meetingSeriesId, base64EncodedUserData, withRouter);
};

const InvitePresenter = ({
  presenter,
  inviteUrl,
  meetingSeriesId,
  localDispatch,
  type,
  disabled,
  isSoloInterview,
}) => {
  const { user: presenterUser } = presenter || {};

  const [isConfirmComplete, setIsConfirmComplete] = useState(false);

  useEffect(() => {
    let timeout;

    if (isConfirmComplete) {
      timeout = setTimeout(() => {
        setIsConfirmComplete(false);
      }, 2_000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isConfirmComplete]);

  return (
    <>
      {presenter && presenter?.emailAddress ? (
        <div className="flex items-center text-sm text-blue-dark justify-between">
          <div>
            Send Calendar Invite to{' '}
            {(presenterUser?.userName || presenter?.fullName) && (
              <>
                <span className="font-medium">
                  {presenterUser?.userName || presenter.fullName}{' '}
                </span>
                at{' '}
              </>
            )}
            <span className="font-medium">{presenter.emailAddress}</span>
          </div>
          <div className="flex gap-2.5 items-center">
            <div className="w-28">
              <Button
                color={Button.colors.PURPLE}
                size={Button.sizes.FULL}
                type="submit"
                confirmLabel={
                  <CompletedButtonLabel
                    text={<span className="text-xxs">Invite Sent</span>}
                  />
                }
                onClick={async () => {
                  const result =
                    type === presenterTypes.SPEAKER
                      ? await sendSpeakerInvite(
                          meetingSeriesId,
                          presenter.emailAddress
                        )
                      : await sendHostInvite(
                          meetingSeriesId,
                          presenter.emailAddress
                        );
                  setIsConfirmComplete(true);
                  if (result?.error) return;
                  localDispatch({
                    type: 'REFRESH_SERIES',
                    series: result,
                  });
                }}
                state={
                  isConfirmComplete
                    ? Button.states.CONFIRM
                    : presenter?.invited || disabled
                    ? Button.states.DISABLED
                    : Button.states.DEFAULT
                }
              >
                <div className="text-xs">Send Invite</div>
              </Button>
            </div>
            {inviteUrl && (
              <CopyToClipboard
                text={inviteUrl}
                onCopy={() =>
                  toast('Link copied to clipboard.', {
                    autoClose: 2000,
                    pauseOnHover: false,
                  })
                }
              >
                <button className="text-blue-light underline decoration-blue-light font-medium text-sm">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
      ) : (
        `Email of the ${type} is required.`
      )}
    </>
  );
};

export const isInviteSpeakerAndHostCompleted = (series) => {
  const { settings } = series || {};
  const { eventPresenters = [] } = settings || {};

  for (let i = 0; i < eventPresenters.length; i++) {
    const eventPresenter = eventPresenters[i];
    if (!eventPresenter?.invited) return false;
  }

  return true;
};

export const PlanInviteSpeakerCard = ({
  series,
  localDispatch,
  disableEventPage,
}) => {
  const { settings, meetingSeriesId, scheduledEvent } = series || {};
  const { startDate, endDate, timezone } = scheduledEvent || {};
  const isDateSet = startDate && endDate && timezone;
  const { eventPresenters = [] } = settings || {};
  const speakers = (eventPresenters || []).filter(
    (ep) => ep.type === presenterTypes.SPEAKER
  );
  const host = eventPresenters.find((ep) => ep.type === presenterTypes.HOST);

  const hostInviteUrl = getHostInviteUrl(host, meetingSeriesId);
  const episodeType = series?.settings?.episodeType;
  const isSoloInterview = episodeType === episodeTypes.solo;

  return (
    <div>
      <div className="px-[18px]">
        <TaskCardHeader className="mb-4">Send Invites</TaskCardHeader>
      </div>
      <div className="px-[18px]">
        {(host || speakers.length > 0) && (isDateSet || isSoloInterview) && (
          <>
            <div className="flex flex-col gap-2 mb-2">
              {speakers.map((speaker) => (
                <InvitePresenter
                  presenter={speaker}
                  inviteUrl={getSpeakerInviteUrl(speaker, meetingSeriesId)}
                  meetingSeriesId={meetingSeriesId}
                  localDispatch={localDispatch}
                  type={presenterTypes.SPEAKER}
                  key={speaker.emailAddress}
                  disabled={disableEventPage}
                  isSoloInterview={isSoloInterview}
                />
              ))}
            </div>
            {isSoloInterview ? null : (
              <InvitePresenter
                presenter={host}
                inviteUrl={hostInviteUrl}
                meetingSeriesId={meetingSeriesId}
                localDispatch={localDispatch}
                type={presenterTypes.HOST}
                disabled={disableEventPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
