import React, { useEffect, useRef } from 'react';
import { Modal, ModalWindow, ModalBody, ModalTitle } from './Modal';
import { LogoSpinnerFullCentered, LogoSpinner } from './LogoSpinner';
import { shallowEqual, useSelector } from 'react-redux';
import { logerror } from '../helper/contextualLogger';
import { useAgencyDetails } from '../hooks/useAgencyDetails';
import Lottie from 'react-lottie';
import fiveDotsLoading from './assets/lottie/fiveDotsLoading.json';

const fiveDotsLoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: fiveDotsLoading,
};

export const ZyncLoadingModal = ({
  message,
  hideSpinner = false,
  title,
  size = Modal.size.xs,
  series,
  children,
  doNotLog = false,
}) => {
  const { isWhiteLabel } = useAgencyDetails();
  const { user, authenticated } = useSelector(
    (state) => state.auth,
    shallowEqual
  );
  const { userId } = user || {};
  const { meetingSeriesId } = series || {};

  const timeout = useRef(null);

  useEffect(() => {
    if (doNotLog) return;
    timeout.current = setTimeout(() => {
      logerror({
        message: 'Zync Loading Modal showing for 10 seconds',
        userId,
        authenticated,
        meetingSeriesId,
      });
    }, 10_000);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [authenticated, doNotLog, meetingSeriesId, userId]);

  return (
    <ModalWindow
      size={size}
      zyncLogo
      showCancel={false}
      boxShadow={Modal.boxShadow['elevation-one']}
    >
      <div className="text-center">
        {title && <ModalTitle>{title}</ModalTitle>}
        <ModalBody>
          {!hideSpinner &&
            (isWhiteLabel ? (
              <div className="w-64">
                <Lottie options={fiveDotsLoadingOptions} />
              </div>
            ) : (
              <LogoSpinnerFullCentered size={LogoSpinner.size.sm} />
            ))}
          {message && <p>{message}</p>}
          {children}
        </ModalBody>
      </div>
    </ModalWindow>
  );
};
