import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash/util';
import { useClickOutside } from '../helper/useClickOutside';
import classNames from '../helper/classNames';
import { Button } from './common/Button';
import { useKeyboardShortcuts } from 'use-keyboard-shortcuts';
import { CheckBox } from './common/CheckBox';
import { UserSettingsContext } from './authoring/userSettingsContext';
import { useAuthoringTool } from './authoring/hooks';
import { HeavyFullViewBackdropBlur } from './HeavyFullViewBackdropBlur';
import { useAgencyDetails } from '../hooks/useAgencyDetails';

const MODAL_OVERLAY_BG = {
  TRANSPARENT: 'TRANSPARENT',
  FADE: 'FADE',
};

const MODAL_OVERLAY_BG_CLASSNAMES = {
  TRANSPARENT: 'bg-transparent',
  FADE: 'bg-black/20',
};

const MODAL_OVERLAY_POSITION = {
  FIXED: 'FIXED',
  RELATIVE: 'RELATIVE',
};

const MODAL_OVERLAY_POSITION_CLASSNAMES = {
  FIXED: 'fixed',
  relative: 'relative',
};

export const ModalOverlay = ({
  children,
  bg = MODAL_OVERLAY_BG.FADE,
  position = MODAL_OVERLAY_POSITION.FIXED,
  overflow,
  className,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div
      className={classNames(
        'inset-0 z-[1001] h-full flex justify-center items-center sm-landscape:h-auto sm-landscape:min-h-full max-h-[100vh] backdrop-blur-3xl',
        overflow ?? '',
        MODAL_OVERLAY_BG_CLASSNAMES[bg],
        MODAL_OVERLAY_POSITION_CLASSNAMES[position],
        className
      )}
    >
      {children}
    </div>
  );
};

ModalOverlay.bg = MODAL_OVERLAY_BG;
ModalOverlay.position = MODAL_OVERLAY_POSITION;

const MODAL_SIZE = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  fullscreen: 'fullscreen',
};

const MODAL_SIZE_CLASSNAMES = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-lg',
  lg: 'max-w-screen-md',
  xl: 'max-w-screen-lg',
  xxl: 'w-[calc(100%-56px)]',
  fullscreen: 'h-full w-full max-h-full max-w-full rounded-none flex',
};

const MODAL_BOX_SHADOW = {
  NONE: 'NONE',
  '3xl': '3xl',
  200: 200,
  'elevation-one': 'elevation-one',
};

const MODAL_BOX_SHADOW_CLASSNAMES = {
  NONE: '',
  '3xl': 'shadow-3xl',
  200: 'shadow-200',
  'elevation-one': 'shadow-elevation-one',
};

const MODAL_BORDER = {
  ROUNDED_MD: 'ROUNDED_MD',
  NONE: 'NONE',
};

const MODAL_BORDER_CLASSNAMES = {
  ROUNDED_MD: 'rounded-md',
  NONE: 'rounded-none',
};
export const Modal = ({
  children,
  size,
  boxShadow,
  border = MODAL_BORDER.ROUNDED_MD,
  onOutsideClick,
  overflow,
  style,
  modalBg,

  // Modal width override
  width,

  // Modal height override
  // height,

  // whether Modal should be shrinking accordingly to screen width. Best used with width and height prop
  // shrinkOnResize = false,
}) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, onOutsideClick || noop);

  return (
    <div
      className={classNames(
        'modal  box-border relative w-full max-h-full',
        overflow ?? '',
        modalBg ? modalBg : 'bg-white',
        MODAL_SIZE_CLASSNAMES[size],
        MODAL_BOX_SHADOW_CLASSNAMES[boxShadow],
        MODAL_BORDER_CLASSNAMES[border]
      )}
      //style={getModalStyleProps(width, height)}
      ref={modalRef}
      style={{ ...style, width }}
    >
      {children}
    </div>
  );
};

Modal.boxShadow = MODAL_BOX_SHADOW;
Modal.size = MODAL_SIZE;
Modal.border = MODAL_BORDER;

const MODAL_TITLE_FONT_COLOR = {
  PURPLE: 'PURPLE',
  BLUE_DARK: 'BLUE_DARK',
};

const MODAL_TITLE_FONT_COLOR_CLASSNAMES = {
  PURPLE: 'text-purple',
  BLUE_DARK: 'text-blue-dark',
};

export const ModalTitle = ({
  children,
  fontColor = MODAL_TITLE_FONT_COLOR.BLUE_DARK,
}) => {
  return (
    <h3
      className={classNames(
        'w-full text-xl font-body font-semibold leading-8 m-0 my-8 px-8',
        MODAL_TITLE_FONT_COLOR_CLASSNAMES[fontColor]
      )}
    >
      {children}
    </h3>
  );
};

ModalTitle.fontColor = MODAL_TITLE_FONT_COLOR;

export const ModalBody = ({ children }) => {
  return <div className="px-6 my-6 md:px-8 md:my-8 grow">{children}</div>;
};

export const ModalButtonsGroup = ({ children }) => {
  return (
    <div className="flex flex-row gap-2 w-full justify-between h-12 box-border px-8 my-8">
      {children}
    </div>
  );
};

const BUTTON_COLOR = {
  PURPLE: 'PURPLE',
  WHITE: 'WHITE',
  RED: 'RED',
};

const BUTTON_COLOR_CLASSNAMES = {
  PURPLE: 'bg-purple text-white border-0',
  WHITE: 'border-light-gray text-blue-gray border border-solid',
  RED: 'bg-red text-white border-0',
};

const BUTTON_FONT_SIZE = {
  base: 'base',
  xl: 'xl',
};

const BUTTON_FONT_SIZE_CLASSNAMES = {
  base: 'text-base',
  xl: 'text-xl',
};

export const ModalButton = ({
  handleOnClick,
  buttonText,
  color = BUTTON_COLOR.PURPLE,
  type = 'button',
  disabled = false,
  fontSize = BUTTON_FONT_SIZE.base,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={classNames(
        'flex-1 rounded font-medium hover:cursor-pointer',
        BUTTON_COLOR_CLASSNAMES[color],
        BUTTON_FONT_SIZE_CLASSNAMES[fontSize]
      )}
      type={type}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
};

const CLOSE_BUTTON_COLOR = {
  BLUE_DARK: 'BLUE_DARK',
  BLUE_GRAY: 'BLUE_GRAY',
};

const CLOSE_BUTTON_COLOR_CLASSNAMES = {
  BLUE_DARK: 'text-blue-dark',
  BLUE_GRAY: 'text-blue-gray',
};

const CLOSE_BUTTON_SIZE = {
  '2xl': '2xl',
  lg: 'lg',
};

const CLOSE_BUTTON_SIZE_CLASSNAMES = {
  '2xl': 'text-2xl',
  lg: 'text-lg',
};

export const CloseButton = ({
  closeButtonPosition = 'absolute right-7 top-7',
  onCancel,
  closeButtonColor = CLOSE_BUTTON_COLOR.BLUE_DARK,
  closeButtonSize = CLOSE_BUTTON_SIZE['2xl'],
}) => {
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      className={classNames(
        'cursor-pointer p-0 border-0 z-[1002]',
        closeButtonPosition,
        CLOSE_BUTTON_COLOR_CLASSNAMES[closeButtonColor],
        CLOSE_BUTTON_SIZE_CLASSNAMES[closeButtonSize]
      )}
      onClick={() => {
        onCancel && onCancel();
        !onCancel && dispatch({ type: 'CLOSE_MODAL_WINDOW' });
      }}
    >
      ×
    </button>
  );
};

CloseButton.color = CLOSE_BUTTON_COLOR;
CloseButton.size = CLOSE_BUTTON_SIZE;

ModalButton.color = BUTTON_COLOR;
ModalButton.fontSize = BUTTON_FONT_SIZE;

export const ModalWindow = ({
  title,
  onCancel,
  children,
  zyncLogo = false,
  showCancel = true,
  size = MODAL_SIZE.lg,
  boxShadow = MODAL_BOX_SHADOW.NONE,
  bg = MODAL_OVERLAY_BG.FADE,
  position = MODAL_OVERLAY_POSITION.FIXED,
  border = MODAL_BORDER.ROUNDED_MD,
  closeButtonPosition = 'absolute right-7 top-7',
  closeButtonColor = CLOSE_BUTTON_COLOR.BLUE_DARK,
  closeButtonSize = CLOSE_BUTTON_SIZE['2xl'],
  width,
  height,
  shrinkOnResize,
  onOutsideClick,
  overflow,
  withBackgroundBlur = false,
  modalBg,
}) => {
  const { orgLogoUrl } = useAgencyDetails();
  return (
    <ModalOverlay
      bg={bg}
      position={position}
      overflow={overflow}
      withBackgroundBlur={withBackgroundBlur}
    >
      {withBackgroundBlur ? <HeavyFullViewBackdropBlur /> : null}
      <Modal
        size={size}
        boxShadow={boxShadow}
        border={border}
        shrinkOnResize={shrinkOnResize}
        width={width}
        height={height}
        onOutsideClick={onOutsideClick}
        overflow={overflow}
        modalBg={modalBg}
      >
        {zyncLogo && (
          <div className="flex justify-center items-center bg-lavender/5 h-24">
            <img src={orgLogoUrl} className="w-28" alt="logo" />
          </div>
        )}
        {showCancel && onCancel && (
          <CloseButton
            onCancel={onCancel}
            closeButtonPosition={closeButtonPosition}
            closeButtonColor={closeButtonColor}
            closeButtonSize={closeButtonSize}
          />
        )}
        {/*This div should be removable once there is approvable that ModalTitle can be used instead. Used DailyMediaSettings as an example.*/}
        <div className="flex items-center justify-center flex-row-reverse relative">
          {title && (
            <h3
              className={classNames(
                'm-0 mb-2 text-lg w-full',
                zyncLogo && 'mt-16'
              )}
            >
              {title}
            </h3>
          )}
        </div>
        {children}
      </Modal>
    </ModalOverlay>
  );
};

export const ConfirmationModal = ({
  onCancel,
  onConfirm,
  title,
  text,
  doNotShowAgainHandler,
  activateKeyboardShortcuts,
  validationFunction,
  confirmButtonText = 'Confirm',
  actionButtons,
  size,
  width,
  height,
  shrinkOnResize,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHiddenNextTime, setIsHiddenNextTime] = useState(false);
  const handleConfirm = useCallback(async () => {
    setLoading(true);

    try {
      const validationResult = validationFunction
        ? await validationFunction()
        : undefined;
      if (validationResult) {
        setError(validationResult);
      }
      if (doNotShowAgainHandler) {
        doNotShowAgainHandler(isHiddenNextTime);
      }
      await onConfirm();
    } finally {
      setLoading(false);
      onCancel();
    }
  }, [
    validationFunction,
    doNotShowAgainHandler,
    onConfirm,
    isHiddenNextTime,
    onCancel,
  ]);

  useKeyboardShortcuts(
    [
      { keys: ['Enter'], onEvent: handleConfirm },
      { keys: ['Escape'], onEvent: onCancel },
    ],
    activateKeyboardShortcuts,
    [activateKeyboardShortcuts, handleConfirm]
  );

  return (
    <ModalWindow
      size={size || Modal.size.lg}
      onCancel={onCancel}
      width={width}
      height={height}
      shrinkOnResize={shrinkOnResize}
    >
      {title && <ModalTitle>{title}</ModalTitle>}
      <ModalBody>
        <div className="flex flex-col gap-8">
          <div className="text-blue-gray text-sm">{text}</div>
          {doNotShowAgainHandler && (
            <CheckBox
              label="Don't show this again"
              onChange={(value) => setIsHiddenNextTime(value)}
              value={isHiddenNextTime}
            />
          )}
          {error && (
            <div className={`text-red text-xs font-light mb-2`}>{error}</div>
          )}
          {actionButtons || (
            <div className="flex justify-end w-full gap-4">
              <Button
                color={Button.colors.RED}
                onClick={handleConfirm}
                state={loading && Button.states.LOADING}
              >
                <div className="text-sm">{confirmButtonText}</div>
              </Button>
              <Button color={Button.colors.WHITE} onClick={onCancel}>
                <div className="text-sm">Cancel</div>
              </Button>
            </div>
          )}
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

export const DeleteSceneConfirmation = ({
  activateKeyboardShortcuts,
  onCancel,
}) => {
  const { handleUpdateSettings } = useContext(UserSettingsContext);

  const { deleteTemplateScene } = useAuthoringTool();

  const handleDoNotShowSceneConfirmation = useCallback(
    (hideDeleteScene) => {
      handleUpdateSettings({ hideDeleteScene });
    },
    [handleUpdateSettings]
  );

  return (
    <ConfirmationModal
      activateKeyboardShortcuts={activateKeyboardShortcuts}
      onCancel={onCancel}
      title="Delete Scene"
      text="Are you sure you want to delete this scene?"
      onConfirm={deleteTemplateScene}
      confirmButtonText="Delete"
      doNotShowAgainHandler={handleDoNotShowSceneConfirmation}
    />
  );
};

/*
Example of how to build your modal:
<ModalWindow zyncLogo={true} size={Modal.size.xs} showCancel={false}>
  <ModalTitle>Thank you!</ModalTitle>
  <ModalBody>
    <p className="text-center">You are being redirected to the summary page</p>
  </ModalBody>
  <ModalButtonsGroup>
      <ModalButton
        handleOnClick={() => {}}
        buttonText={'Cancel'}
        color={ModalButton.color.WHITE}
      />
      <ModalButton
        handleOnClick={() => {}}
        buttonText={'Add User'}
      />
  </ModalButtonsGroup>
</ModalWindow>
*/
