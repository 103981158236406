import React from 'react';
import { EventLink } from '../NewPortal';
import { getTextFromRichText } from 'zync-common/helper/richText';
import { Link } from 'react-router-dom';
import { Button } from '../../components/common/Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import { getSpeakerInviteUrl } from '../EventLanding/Plan/PlanInviteSpeakerCard';
import classNames from '../../helper/classNames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../components/icons/CopyIcon';

export const SoloEpisodeCard = ({
  series,
  showImages = true,
  setShowDesktopOnlyModal,
}) => {
  const { meetingSeriesId } = series || {};

  const contentKitStatus = series?.contentKitStatus?.status;
  const completed = Boolean(contentKitStatus); // any status

  return (
    <article
      className={classNames(
        `flex md:gap-6 gap-2 p-4 pl-6 border border-[#E6E8F0] relative md:items-center md:flex-row flex-col min-h-[72px]`,
        series?.contentKitStatus?.status === 'COMPLETE'
          ? 'bg-purple/5'
          : 'bg-white'
      )}
    >
      <div className="flex-[10_0_0%] flex items-center">
        <EventLink
          event={series}
          setShowDesktopOnlyModal={setShowDesktopOnlyModal}
          isContentKitCompleted={completed}
        >
          <h2 className="text-sm text-blue-dark font-medium cursor-pointer">
            {getTextFromRichText(series.title)}
          </h2>
        </EventLink>
      </div>
      <div
        className={`flex-[8_0_0%] flex flex-wrap justify-end gap-y-2 ${
          showImages ? 'gap-x-4' : 'gap-x-7'
        }`}
      >
        {!completed ? (
          <div className="relative z-100 w-[180px]">
            <Button
              color={Button.colors.PURPLE}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                window
                  .open(
                    getSpeakerInviteUrl(
                      series.settings.eventPresenters[0],
                      series.meetingSeriesId,
                      true
                    ),
                    '_blank'
                  )
                  .focus();
              }}
            >
              <span className="text-sm font-medium no-underline whitespace-nowrap">
                Launch Session
              </span>
            </Button>
          </div>
        ) : null}
        {contentKitStatus !== 'COMPLETE' &&
          contentKitStatus !== 'PROCESSING' &&
          contentKitStatus !== 'WAITING FOR INPUT' && (
            <CopyToClipboard
              text={getSpeakerInviteUrl(
                series.settings.eventPresenters[0],
                series.meetingSeriesId,
                false
              )}
            >
              <button>
                <CopyIcon />
              </button>
            </CopyToClipboard>
          )}
        {contentKitStatus === 'COMPLETE' ? (
          <div className="w-[180px]">
            <div className="flex gap-1 items-center">
              <ArrowRightIcon
                className="fill-blue-light -rotate-45"
                width={12}
                height={12}
              />
              <Link
                to={`/e/${meetingSeriesId}/details/publish?`}
                className="font-jakarta text-xs font-semibold underline text-blue-light"
              >
                Content Kit
              </Link>
            </div>
          </div>
        ) : null}

        {contentKitStatus === 'ERROR' ? (
          <div className="w-[180px]">
            <div className="flex gap-1 items-center mb-2">
              <ArrowRightIcon
                color="#97A0AF"
                className="fill-[#97A0AF] -rotate-45"
                width={12}
                height={12}
              />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                aria-disabled
                className="font-jakarta text-xs font-semibold underline text-[#97A0AF] pointer-events-none"
              >
                Content Kit
              </a>
            </div>
            <p className="text-xxs font-medium text-red">
              Error producing Content kit
            </p>
          </div>
        ) : null}

        {contentKitStatus === 'PROCESSING' ||
        contentKitStatus === 'WAITING_FOR_CALLBACK' ||
        contentKitStatus === 'WAITING FOR INPUT' ||
        contentKitStatus === 'INCOMPLETE' ? (
          <div className="w-[180px]">
            <div className="flex gap-1 items-center mb-2">
              <ArrowRightIcon
                color="#97A0AF"
                className="fill-[#97A0AF] -rotate-45"
                width={12}
                height={12}
              />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                aria-disabled
                className="font-jakarta text-xs font-semibold underline text-[#97A0AF] pointer-events-none"
              >
                Content Kit
              </a>
            </div>
            <p className="text-xxs font-medium text-blue-gray">
              Content Kit is being processed
            </p>
          </div>
        ) : null}
      </div>
    </article>
  );
};
