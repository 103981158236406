import { useEffect, useState } from 'react';
import { getOrganizationByDomain } from '../helper/api';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import {
  HOW_SOLO_STUDIO_WORKS_URL,
  TROUBLESHOOTING_URL,
} from '../helper/constants';
import { useSelector } from 'react-redux';

export const DEFAULT_HOSTS = {
  'app.zync.ai': true,
  'test.zync.ai': true,
  localhost: true, // if false, we will fetch organization with domainName localhost. Good for testing white label updates
};

const defaultAgencyDetails = {
  orgId: 'zync',
  domainName: 'app.zync.ai',
  orgName: 'Zync',
  orgLogoUrl: getStaticAssetUrl('logo_text.png'),
  orgLogoLightUrl: getStaticAssetUrl('logo-text-white.svg'),
  orgLogoDarkUrl: getStaticAssetUrl('logo_text_black.png'),
  orgTroubleshootingGuideUrl: TROUBLESHOOTING_URL,
  orgHowSoloStudioWorkUrl: HOW_SOLO_STUDIO_WORKS_URL,
  settings: {
    mobileAppEnabled: false,
  },
  //ownerId: '',
};

export const useInitializeAgencyDetails = (store) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const hostName = window.location.hostname;

  useEffect(() => {
    const getAgencyDetails = async () => {
      let data = defaultAgencyDetails;

      if (!DEFAULT_HOSTS[hostName]) {
        data = await getOrganizationByDomain(hostName);
      }

      store.dispatch({
        type: 'SET_AGENCY_DETAILS',
        payload: data,
      });

      setIsLoaded(true);
    };

    window.store = store;

    getAgencyDetails();
    // eslint-disable-next-line
  }, [hostName]);

  return { isLoaded };
};

export const useAgencyDetails = () => {
  const organizationName = useSelector((state) => state.agencyDetails?.orgName);
  const domainName = useSelector((state) => state.agencyDetails?.domainName);
  const orgLogoDarkUrl = useSelector(
    (state) => state.agencyDetails?.orgLogoDarkUrl
  );
  const orgLogoLightUrl = useSelector(
    (state) => state.agencyDetails?.orgLogoLightUrl
  );
  const orgLogoUrl = useSelector((state) => state.agencyDetails?.orgLogoUrl);
  const orgTroubleshootingGuideUrl = useSelector(
    (state) => state.agencyDetails?.orgTroubleshootingGuideUrl
  );
  const orgHowSoloStudioWorkUrl = useSelector(
    (state) => state.agencyDetails?.orgHowSoloStudioWorkUrl
  );
  const orgSettings = useSelector((state) => state.agencyDetails?.settings);

  const isWhiteLabel = !DEFAULT_HOSTS[domainName];

  return {
    organizationName,
    domainName,
    orgLogoUrl,
    orgTroubleshootingGuideUrl,
    orgHowSoloStudioWorkUrl,
    orgLogoLightUrl,
    orgLogoDarkUrl,
    orgSettings,
    isWhiteLabel,
  };
};
