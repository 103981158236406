import log from 'loglevel';

export function fromError(errorObjectOrString, message) {
  let messageToLog =
    (typeof errorObjectOrString === 'string'
      ? errorObjectOrString
      : errorObjectOrString.message) +
    ' ' +
    window.location.href;

  if (message) {
    messageToLog = `${message}: ${
      errorObjectOrString.message || errorObjectOrString
    }`;
  }

  return {
    message: messageToLog,
    stacktrace: errorObjectOrString.stack,
  };
}

export const loginfo = (object) => {
  logger('info', object);
};

export const logwarn = (object) => {
  logger('warn', object);
};

export const logerror = (object) => {
  logger('error', object);
};

export const logdebug = (object) => {
  logger('debug', object);
};

const logger = (
  level,
  {
    userId,
    meetingId,
    meetingSeriesId,
    message,
    stacktrace,
    redirectUrl,
    emailAddress,
    authenticated,
    url,
    context = {},
  }
) => {
  if (!message) return;

  const logMessage = JSON.stringify({
    userId,
    meetingSeriesId,
    meetingId,
    message,
    stacktrace,
    url: window.location.href,
    origin: url,
    redirectUrl,
    emailAddress,
    authenticated,
    context,
  });
  if (level === 'warn') log.warn(logMessage);
  else if (level === 'debug') log.debug(logMessage);
  else if (level === 'error') log.error(logMessage);
  else log.info(logMessage);
};

/** A higher order function used for providing error catching / logging mechanism to existing fns */
export const withCatchAllAndLogging =
  (fn) =>
  (...args) => {
    try {
      return fn(...args);
    } catch (error) {
      logerror({
        message: error.message,
        stacktrace: error.stack,
        context: args ? JSON.stringify(args) : undefined,
      });
      return {};
    }
  };
