import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getSpeakerInviteUrl } from '../EventLanding/Plan/PlanInviteSpeakerCard';
import { CompletedContentKitIcon } from './MobileSoloPortalIcons';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';

const mobileAlert = getStaticAssetUrl('mobile-alert.png');

export const MobileSoloEpisodeCard = ({ series }) => {
  const { meetingSeriesId, title } = series || {};

  const contentKitStatus = series?.contentKitStatus?.status;
  const completed = Boolean(contentKitStatus); //any status

  return (
    <div className="bg-[#F0F2F5] border border-[#EDEFF2] rounded-[15px] pl-[25px] pr-[15px] py-4 flex justify-between items-center">
      <div className="max-w-[60%] text-[#444444] text-sm font-medium break-words">
        {title}
      </div>
      {/*<Link
        to={
          completed
            ? `/e/${meetingSeriesId}/details/publish?`
            : `/e/${meetingSeriesId}/details?workspaceId=${workspace?.workspaceId}`
        }
        className="max-w-[60%]"
      >
        <div className="text-[#444444] text-sm font-medium break-words">
          {title}
        </div>
      </Link>*/}
      <div className="flex w-[87px] justify-center items-center">
        {!completed && (
          <button
            className="w-[87px] h-[32px] flex justify-center items-center text-white font-montserrat font-bold text-xs bg-[#5C41FF] rounded-lg"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              window
                .open(
                  getSpeakerInviteUrl(
                    series.settings.eventPresenters[0],
                    series.meetingSeriesId,
                    true
                  ),
                  '_blank'
                )
                .focus();
            }}
          >
            Launch
          </button>
        )}
        {contentKitStatus === 'COMPLETE' && (
          <Link to={`/e/${meetingSeriesId}/details/publish?`}>
            <CompletedContentKitIcon />
          </Link>
        )}
        {(contentKitStatus === 'PROCESSING' ||
          contentKitStatus === 'WAITING_FOR_CALLBACK' ||
          contentKitStatus === 'WAITING FOR INPUT' ||
          contentKitStatus === 'INCOMPLETE') && (
          <div className="rounded-md w-[87px] h-[30px] flex justify-center items-center bg-[#00C92C] gap-1 px-1.5">
            <img src={mobileAlert} className="w-[17px] h-[17px]" alt="" />
            <span className="font-roboto text-white font-bold text-[7px]">
              Content Kit is being processed
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
