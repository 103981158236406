import { logerror, loginfo, logwarn } from './contextualLogger';
import { makeRandomId } from './index';
import { exponentialFetchRetry } from './exponentialFetchRetry';
import { episodeTypes, meetingTypes } from 'zync-common/types';
import { convertBlockSettings } from '../components/authoring/hooks';
import { convertToDynamicValue } from 'zync-common/helper/overrideDynamicValue';
import {
  CONTENT_KIT_ASSET_BASE_URL,
  CONTENT_KIT_ASSETS_BASE_URL,
  CONTENT_KIT_BASE_URL,
  PREPARATION_KIT_BASE_URL,
  PROCESSOR_BASE_URL,
  PROMOTION_KIT_BASE_URL,
} from './constants';
import axios from 'axios';

let socket = null;
export const setSocket = (s) => {
  socket = s;
};

const isSocketConnected = () => {
  return socket && socket.connected;
};

const sendRequest = async (url, fetchOptions = {}, loggingContext = {}) => {
  return exponentialFetchRetry({
    fetchHandler: async () => {
      const response = await fetch(url, {
        ...fetchOptions,
      });
      return response;
    },
    jsonResponseHandler: async (response) => {
      return response.json().then((data) => {
        return data;
      });
    },
    attributesToLog: {
      ...loggingContext,
      url,
      method: fetchOptions?.method,
    },
  });
};

export const postJson = async (url, data, loggingContext) =>
  await sendRequest(
    url,
    {
      method: 'POST',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify(data),
    },
    loggingContext
  );

export const sendEvent = async (userId, meetingId, action) => {
  if (!meetingId) {
    logerror({
      userId,
      meetingSeriesId: meetingId,
      message: `sendEvent was called without meetingId - ${meetingId}.${
        action ? ` From action ${JSON.stringify(action)}` : ''
      }`,
    });

    return;
  }

  const event = Object.assign({}, action, { meetingId, userId });

  // If the client has a connected socket, send the action via a socket
  if (isSocketConnected()) {
    socket.emit('clientAction', event);
    return;
  }

  // If not send via a rest api call
  const response = await postJson(`/api/meeting-v1/${meetingId}/event`, event, {
    userId,
    meetingId,
    event,
  });

  if (response.error) {
    logerror({
      userId,
      message: response.error,
    });
    return {};
  }

  return response.result;
};

export const startRecording = async ({ meetingId, streamId }) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/recording`,
    { operation: 'START_RECORDING', streamId },
    {
      meetingId,
    }
  );
  return json.result;
};

export const stopRecording = async (meetingId) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/recording`,
    { operation: 'STOP_RECORDING' },
    {
      meetingId,
    }
  );
  return json.result;
};

export const fetchSeriesApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}`,
    null,
    {
      meetingSeriesId,
    }
  );
  return json.result;
};

var defaultGridConfig;
export const createNewSeriesApi = async (
  workspaceId,
  userId,
  title,
  description,
  visibility,
  launchConfig = undefined,
  scheduledEvent = undefined,
  type = meetingTypes.STANDARD,
  newMeetingSeriesId = undefined,
  onboardingCompleted = false,
  AIGenerationStatuses,
  eventLandingPageTaskStatuses,
  eventRegistrationSocialMediaPosts,
  episodeType = episodeTypes.default
) => {
  if (!defaultGridConfig)
    defaultGridConfig = (await fetchTemplatesApi()).find(
      (template) => template.templateId === 'defaultgrid'
    );
  const json = await postJson(
    `/api/meetingseries`,
    {
      userId,
      workspaceId,
      title,
      description,
      visibility,
      launchConfig: launchConfig ? launchConfig : defaultGridConfig,
      scheduledEvent: scheduledEvent ? scheduledEvent : {},
      type,
      newMeetingSeriesId,
      onboardingCompleted,
      AIGenerationStatuses,
      eventLandingPageTaskStatuses,
      eventRegistrationSocialMediaPosts,
      episodeType,
    },
    { userId }
  );
  if (json.error) {
    logerror({
      userId,
      message: 'Error creating meeting series: ' + json.error,
    });
    return {};
  }
  return json.result;
};

export const duplicateSeriesApi = async ({
  meetingSeriesId,
  userId,
  workspaceId,
}) => {
  const response = await postJson(`/api/duplicate-series`, {
    meetingSeriesId,
    userId,
    workspaceId,
  });

  if (response.error) {
    logerror({
      userId,
      meetingSeriesId,
      workspaceId,
      message: 'Error duplicating meeting series: ' + response.error,
    });
    return {};
  }

  return response.result;
};

export const updateSeriesApi = async (meetingSeriesId, series) => {
  const updatedSeries = {
    ...series,
    autoLaunchConfig: series?.autoLaunchConfig
      ? {
          ...series.autoLaunchConfig,
          slides: series.autoLaunchConfig.slides.map((slide) => ({
            ...slide,
            slideBackgroundColor: convertToDynamicValue(
              slide.slideBackgroundColor,
              series.workspace.brandKit
            ),
            slideConfig: {
              ...slide.slideConfig,
              slideBlocks: slide.slideConfig.slideBlocks.map((block) => ({
                blockId: block.blockId,
                settings: convertBlockSettings(
                  { ...block.settings },
                  series.workspace.brandKit,
                  block.blockId
                ),
              })),
            },
          })),
        }
      : undefined,
  };

  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/update`,
    updatedSeries,
    { meetingSeriesId }
  );
  return json.result;
};

/**
 * Updates settings for the scene identified by `sceneId`.
 *
 * @returns true if the scene was updated, or an error string.
 */
export const updateSceneSettingsApi = async (
  meetingSeriesId,
  sceneId,
  /** A map of settings values. */
  settings
) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene/${sceneId}/settings`,
    {
      method: 'PATCH',
      body: JSON.stringify({ settings }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

/**
 * Reorder scenes/slides in the meeting series.
 *
 * @returns true if the scenes were reordered, or an error string.
 */
export const reorderScenesApi = async (meetingSeriesId, sceneIdsInOrder) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene/all/settings`,
    {
      method: 'PATCH',
      body: JSON.stringify({ order: sceneIdsInOrder }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {
      meetingSeriesId,
    }
  );
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

/**
 * Add one or more slides/scenes to the meeting series.
 *
 * @returns true if the scenes were added, or an error string.
 */
export const addScenesApi = async (meetingSeriesId, slides, targetIndex) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slides, index: targetIndex }),
    },
    {
      meetingSeriesId,
    }
  );
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

/**
 * Deletes the slide/scene from the meeting series with a DELETE method.
 *
 * @returns true if the scene was deleted, or an error string if there was an error.
 */
export const deleteSceneApi = async (meetingSeriesId, sceneId) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene/${sceneId}`,
    { method: 'DELETE' },
    {
      meetingSeriesId,
    }
  );
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

/**
 * Deletes the block from the slide with a DELETE method.
 *
 * @returns true if the block instance was deleted from the meeting series scene, or an error string if there was an error.
 */
export const deleteBlockInstanceApi = async (
  meetingSeriesId,
  sceneId,
  blockInstanceId
) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene/${sceneId}/block/${blockInstanceId}`,
    {
      method: 'DELETE',
    },
    {
      meetingSeriesId,
    }
  );

  const { result, error } = json;
  return result ? Boolean(result) : error;
};

/**
 * Adds blocks to a scene.
 *
 * @returns true if the blocks were added, an error string if there was an error.
 */
export const addBlocksApi = async (meetingSeriesId, sceneId, blocks) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene/${sceneId}/block`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ blocks }),
    },
    { meetingSeriesId }
  );

  const { result, error } = json;
  return result ? Boolean(result) : error;
};

/**
 * Updates one or more settings in the settings object with a PATCH method.
 *
 * @returns true if the settings were updated, an error string if there was an error, or false if there was no error
 *          but the settings were not modified by the query.
 */
export const updateBlockSettingsApi = async (
  meetingSeriesId,
  sceneId,
  blockInstanceId,
  blockId,
  settings
) => {
  const json = await sendRequest(
    `/api/meetingseries/${meetingSeriesId}/scene/${sceneId}/block/${blockInstanceId}/settings`,
    {
      method: 'PATCH',
      body: JSON.stringify({ settings, blockId }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

export const deleteSeriesApi = async (meetingSeriesId) => {
  const json = await sendRequest(`/api/meetingseries/${meetingSeriesId}`, {
    method: 'DELETE',
  });
  return json.result;
};

export const getMeetingListApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `/api/meeting-v1?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    logerror({
      meetingSeriesId,
      message: json.error,
    });
    return [];
  }
  return json.result;
};

export const fetchFeedDataApi = async (workspaceId) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/feeddata?workspaceId=${workspaceId}`
  );

  if (json.error) {
    loginfo({
      workspaceId,
      message: json.error,
    });
    return undefined;
  }
  return json.result;
};

export const fetchEpisodeDataApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/episodedata?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    logwarn({
      meetingSeriesId,
      message: json.error,
    });
    return undefined;
  }
  return json.result;
};

export const fetchPodcastDataApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/podcastdata?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    logerror({
      meetingSeriesId,
      message: json.error,
    });
    return undefined;
  }
  return json.result;
};

export const fetchPromotionKitDataApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `${PROMOTION_KIT_BASE_URL}/promotion-kit-data?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    return undefined;
  }
  return json.result;
};

export const fetchStartedPromotionKitApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `${PROMOTION_KIT_BASE_URL}/started-promotion-kit?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    return undefined;
  }
  return json.result;
};

export const fetchContentKitDataApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/contentkitdata?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    return undefined;
  }
  return json.result;
};

export const fetchStartedContentKitApi = async (meetingSeriesId) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/started-content-kit?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    return undefined;
  }
  return json.result;
};

export const fetchShowDataApi = async (workspaceId) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/showdata?workspaceId=${workspaceId}`
  );

  if (json.error) {
    return undefined;
  }
  return json.result;
};

export const fetchSpeakerKitDataApi = async (meetingSeriesId, emailAddress) => {
  const json = await sendRequest(
    `${CONTENT_KIT_BASE_URL}/speakerkitdata?meetingSeriesId=${meetingSeriesId}&emailAddress=${encodeURIComponent(
      emailAddress
    )}`
  );

  if (json.error) {
    return undefined;
  }
  return json.result;
};

export const getLatestCompletedNonRehearsalMeetingApi = async (
  meetingSeriesId
) => {
  const json = await sendRequest(
    `/api/meeting-v1/latest-non-rehearsal-completed?meetingSeriesId=${meetingSeriesId}`
  );

  if (json.error) {
    logerror({
      meetingSeriesId,
      message: json.error,
    });
    return { error: true };
  }
  return json.result;
};

export const fetchMeetingApi = async (meetingId) => {
  const json = await sendRequest(`/api/meeting-v1/${meetingId}`, null, {
    meetingId,
  });
  return json.result;
};

export const endRunningMeeting = async (meetingSeriesId, userId) => {
  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/endRunningMeeting`,
    { userId },
    { userId, meetingSeriesId }
  );
  if (json.error) {
    logerror({
      meetingSeriesId,
      message: json.error,
      userId,
    });
    return {};
  }
  return json.result;
};

export const sendMeetingSummaryEmail = async (meetingId, emailAddress) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/email-summary`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailAddress,
      }),
    },
    {
      meetingId,
    }
  );
  return json;
};

export const fetchBlocksApi = async () => {
  const json = await sendRequest('/api/blocks');
  return json.result || [];
};

export const fetchUserApi = async (userId) => {
  const json = await sendRequest(
    `/api/user?userId=${encodeURIComponent(userId)}`
  );
  return json.result;
};

/**
 * Updates a user's properties.
 *
 * Note that only a subset of the user's properties can be modified.
 *
 * @returns the user object if it was updated, or an error string.
 */
export const updateUserApi = async (userId, props) => {
  const json = await sendRequest(`/api/user`, {
    method: 'PATCH',
    body: JSON.stringify({ userId, ...props }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

export const addAdminApi = async (workspaceId, emailAddress) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}/admin`, {
    method: 'POST',
    body: JSON.stringify({ emailAddress }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const { result, error } = json;
  return result ? result : { error };
};

/**
 * Add the given block ID to the user's list of viewed blocks. Used to determine
 * when to show any given block tutorial.
 */
export const addUserViewedBlockApi = async (userId, viewedBlockId) => {
  const json = await sendRequest(`/api/user/viewedBlocks`, {
    method: 'PUT',
    body: JSON.stringify({ userId, viewedBlockId }),
    headers: { 'Content-Type': 'application/json' },
  });
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

export const updateUserProfileApi = async (
  userId,
  userProfile,
  workspaceId = undefined,
  updateSpeaker = false
) => {
  const json = await sendRequest(`/api/user/profile`, {
    method: 'PUT',
    body: JSON.stringify({
      userId,
      workspaceId,
      updateSpeaker,
      ...userProfile,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  const { result, error } = json;
  return result ? Boolean(result) : error;
};

export const updateUserConsentsApi = async (userId, consents) => {
  const json = await sendRequest(`/api/user/consents`, {
    method: 'PUT',
    body: JSON.stringify({ userId, consents }),
    headers: { 'Content-Type': 'application/json' },
  });

  const { result, error } = json;
  return result ? Boolean(result) : error;
};

export const fetchWorkspaceApi = async (workspaceId) => {
  const json = await sendRequest(
    `/api/workspace?workspaceId=${encodeURIComponent(workspaceId)}`
  );

  return json;
};

export const createWorkspaceApi = async (name, userId, options) => {
  const json = await sendRequest(`/api/workspace`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, userId, ...options }),
  });

  return json;
};

export const updateWorkspaceApi = async (workspaceId, fields, userId) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...fields, userId }),
  });

  return json;
};

export const publishWorkspaceApi = async (workspaceId) => {
  return await sendRequest(`/api/workspace/${workspaceId}/publish`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const publishRssFeedApi = async (workspaceId) => {
  return await sendRequest(`/api/workspace/${workspaceId}/publish-rss-feed`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const publishFeedPageApi = async (workspaceId) => {
  return await sendRequest(`/api/workspace/${workspaceId}/publish-feed-page`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const inviteWorkspaceMemberApi = async (
  workspaceId,
  emailAddress,
  inviterEmailAddress,
  inviterUserName
) => {
  const json = await sendRequest(
    `/api/workspace/${workspaceId}/invite`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailAddress,
        inviterEmailAddress,
        inviterUserName,
      }),
    },
    {
      userId: inviterEmailAddress,
    }
  );
  return json;
};

export const upsertWorkspaceMemberApi = async (
  workspaceId,
  userId,
  plan,
  isAdmin,
  isProducer
) => {
  const json = await sendRequest(`/api/user/workspaces`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ workspaceId, userId, plan, isAdmin, isProducer }),
  });
  return json;
};

export const removeWorkspaceMemberApi = async (workspaceId, userId) => {
  const json = await sendRequest(`/api/user/workspaces`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ workspaceId, userId }),
  });
  const { result } = json;
  return result;
};

export const createWorkspaceIntegrationApi = async (
  workspaceId,
  streamIntegration
) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}/integration`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...streamIntegration, type: 'stream' }),
  });
  return json;
};

export const updateWorkspaceIntegrationApi = async (
  workspaceId,
  streamIntegration
) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}/integration`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...streamIntegration, type: 'stream' }),
  });
  return json;
};

export const deleteWorkspaceIntegrationApi = async (workspaceId, streamId) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}/integration`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ streamId, type: 'stream' }),
  });
  return json;
};

export const updateWorkspaceCalendlyIntegrationApi = async (
  workspaceId,
  calendlyObject
) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ calendly: calendlyObject }),
  });

  return json;
};

export const updateWorkspaceLinkedinIntegrationApi = async (
  workspaceId,
  linkedInObject
) => {
  const json = await sendRequest(`/api/workspace/${workspaceId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ linkedin: linkedInObject }),
  });

  return json;
};

export const fetchTemplatesApi = async () => {
  const json = await sendRequest(`/api/template`);

  if (json.error) {
    logerror({ message: `Error fetching templates library: ${json.error}` });
    return [];
  }
  return json.result;
};

export const fetchEventTemplateApi = async (templateKey) => {
  const json = await sendRequest(`/api/template/${templateKey}`);

  if (json.error) {
    logerror({
      message: `Error fetching a template ${templateKey}: ${json.error}`,
    });
    return null;
  }

  return json.result;
};

export const fetchSceneTemplateApi = async (templateKey) => {
  const json = await sendRequest(`/api/scene-template/${templateKey}`);

  if (json.error) {
    logerror({
      message: `Error fetching scene templates: templateKey:${templateKey} ${json.error}`,
    });
    return null;
  }

  return json.result;
};

/** Import a google slide deck into a meeting series. */
export const addGoogleSlidesToMeetingSeriesApi = async (
  meetingSeriesId,
  slidesUrlOrPresentationId
) => {
  const parts = slidesUrlOrPresentationId.split('/');
  const presentationId =
    parts.length === 1
      ? slidesUrlOrPresentationId
      : parts[parts.indexOf('presentation') + 2];

  const json = await sendRequest(
    `/api/template/images?meetingSeriesId=${meetingSeriesId}&presentationId=${presentationId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (json.error) {
    logerror({
      meetingSeriesId,
      message: `Error adding google slides to meeting series. ${json.error}`,
    });
    // This is a problematic pattern- there is no way to discern between network exceptions and
    // 'error' data returned from the server.
    // TODO: Do not throw here, return json.error, or just return json and let the caller handle it.
    throw Error(json.error);
  } else {
    return json.result;
  }
};

/**
 * Video Room Apis
 */

export const createDailyMeetingRoom = async (meetingId) => {
  const json = await postJson(
    `/api/daily/meeting`,
    { meetingId },
    { meetingId }
  );

  if (json.error) {
    logerror({
      meetingId,
      message: `Error creating daily meeting room: ${json.error}`,
    });
    return {};
  }
  return json.result;
};

export const deleteDailyMeetingRoom = async (meetingId) => {
  const json = await sendRequest(`/api/daily/meeting/${meetingId}`, {
    method: 'DELETE',
  });
  return json.result;
};

export const createMeetingToken = async (meetingId, properties) => {
  const json = await postJson(
    `/api/daily/meeting/token`,
    { meetingId, properties },
    { meetingId }
  );

  if (json.error) {
    logerror({
      meetingId,
      message: `Error creating meeting token: ${json.error}`,
    });
    return null;
  }

  return json.token;
};

export const getUploadedSeriesImages = async (prefix) => {
  const json = await sendRequest(
    `/media/images?` +
      new URLSearchParams({
        prefix,
      })
  );

  if (json.error) {
    logerror({
      message: `Error getting uploaded series images: ${json.error}`,
    });
    return [];
  }

  return json.result;
};

/** Get a map of { [category]: list[] } containing default scene backdrop urls from media. */
export const fetchDefaultSceneBackdropsApi = async () => {
  const THUMBNAIL_MARKER = '_thumbnail';
  const json = await sendRequest('/media/standard-scene-backdrops');

  if (json.error) {
    logerror({
      message: `Error fetching default scene backdrops: ${json.error}`,
    });
    return [];
  } else {
    const images = json.result
      .filter(
        (file) =>
          file.fileName && file.contentType.includes('image') && file.publicUrl
      )
      .reduce((files, file) => {
        const isThumbnail = file.publicUrl.includes(THUMBNAIL_MARKER);
        const key = file.publicUrl.replace(THUMBNAIL_MARKER, '');
        if (isThumbnail) {
          files[key] = {
            ...files[key],
            thumbnailImage: file.publicUrl,
          };
        } else {
          files[key] = {
            ...files[key],
            ...file,
            image: file.publicUrl,
          };
        }

        return files;
      }, {});

    const result = Object.values(images).reduce((memo, file) => {
      const split = file.fileName.split('-');
      const category = split
        .slice(0, split.length - 1)
        .map((term) => term[0].toUpperCase() + term.slice(1))
        .join(' ');
      return {
        ...memo,
        [category]: [
          ...(memo[category] ?? []),
          {
            imageUrl: file.publicUrl,
            thumbnailImageUrl: file.thumbnailImage || file.publicUrl,
          },
        ],
      };
    }, {});
    return result;
  }
};

export const uploadBlockImage = async (fileName, buffer, contentType) => {
  const json = await sendRequest(
    `/api/template/block-images?fileName=${fileName}`,
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  if (json.error) {
    logerror({
      message: `Error uploading block image: ${json.error}`,
    });

    throw Error(json.error);
  } else {
    return json.result;
  }
};

export const uploadSeriesSlideImage = async (fileName, buffer, contentType) => {
  const json = await sendRequest(
    '/media/images?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  return json;
};

export const uploadBrandKitLogo = async (fileName, buffer, contentType) => {
  const json = await sendRequest(
    '/media/brand-kit?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  if (json.error) {
    return null;
  }

  return json;
};

export const uploadCustomShowpagePicture = async (
  fileName,
  buffer,
  contentType
) => {
  const json = await sendRequest(
    '/media/brand-kit/?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  if (json.error) {
    return null;
  }

  return json;
};

export const uploadPosterImage = async (fileName, buffer, contentType) => {
  const json = await sendRequest(
    '/media/posters?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  return json;
};

export const uploadUserImage = async (fileName, buffer, contentType) => {
  const json = await sendRequest(
    '/media/user?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  return json;
};

export const uploadEventPresenterImage = async (
  fileName,
  buffer,
  contentType
) => {
  const json = await sendRequest(
    '/media/event-presenters?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  return json;
};

export const uploadIntroOutroVideo = async (fileName, buffer, contentType) => {
  const json = await sendRequest(
    '/media/video?' +
      new URLSearchParams({
        fileName,
      }),
    {
      method: 'POST',
      headers: { 'Content-Type': contentType },
      body: buffer,
    }
  );

  return json;
};

export const createMeetingTemplateApi = async (
  categories,
  templateName,
  templateDescription,
  templateImageUrl,
  visibility,
  templateKey,
  imports,
  slides,
  displayImportance,
  meetingSeriesId,
  userId,
  fakeUsers
) => {
  const json = await postJson(
    '/api/meeting-template',
    {
      categories,
      templateName,
      templateDescription,
      templateImageUrl,
      visibility,
      templateKey,
      imports,
      slides,
      displayImportance,
      fakeUsers,
    },
    { userId, meetingSeriesId }
  );
  if (json.error) {
    return { error: 'Error creating meeting template: ' + json.error };
  }
  return json.result;
};

export const updateMeetingTemplateApi = async (
  categories,
  templateName,
  templateDescription,
  templateImageUrl,
  visibility,
  templateKey,
  imports,
  slides,
  displayImportance,
  userId,
  templateId,
  fakeUsers
) => {
  const json = await sendRequest(
    '/api/meeting-template',
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        categories,
        templateName,
        templateDescription,
        templateImageUrl,
        visibility,
        templateKey,
        imports,
        slides,
        displayImportance,
        templateId,
        fakeUsers,
      }),
    },
    { userId, templateId }
  );
  if (json.error) {
    return { error: 'Error updating meeting template: ' + json.error };
  }
  return json.result;
};

export const deleteMeetingTemplateApi = async (templateId, userId) => {
  const json = await sendRequest(
    '/api/meeting-template',
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ templateId }),
    },
    { userId }
  );

  if (json.error) {
    logerror({
      userId,
      templateId,
      message: 'Error deleting meeting template: ' + json.error,
    });
    return {};
  }
  return json.result;
};

export const fetchSceneTemplatesApi = async () => {
  const json = await sendRequest('/api/scene-template');
  return json.result;
};

export const convertSceneToSceneTemplate = (
  scene,
  sceneTemplateIcon = null
) => ({
  sceneTemplateId: makeRandomId(8),
  title: scene.slideName,
  thumbnailUrl: sceneTemplateIcon ?? scene.slideUrl,
  description: scene.sceneDescription ?? '',
  settings: {
    slideUrl: scene.slideUrl,
  },
  blocks: scene.slideConfig.slideBlocks.map((block) => ({
    blockId: block.blockId,
    settings: block.settings,
  })),
  displayImportance: scene?.displayImportance ?? 10,
  tags: scene?.tags ?? [],
  visibility: scene?.visibility ?? 'public',
  sceneTemplateKey: scene?.sceneTemplateKey ?? '',
  slideBackgroundColor: scene?.slideBackgroundColor,
});

export const addSceneTemplateFromCurrentSceneApi = async (
  currentScene,
  sceneTemplateIcon = null,
  userId,
  meetingSeriesId,
  sceneId
) => {
  const template = convertSceneToSceneTemplate(currentScene, sceneTemplateIcon);
  return await addSceneTemplateApi(template, userId, meetingSeriesId, sceneId);
};

export const addSceneTemplateApi = async (
  sceneTemplate,
  userId,
  meetingSeriesId,
  sceneId
) => {
  const json = await postJson(`/api/scene-template`, sceneTemplate, {
    userId,
    meetingSeriesId,
    sceneId,
  });

  if (json.error) {
    return { error: 'Error creating scene template: ' + json.error };
  }
  return json.result;
};

export const updateSceneTemplateApi = async (
  userId,
  sceneTemplateId,
  title,
  thumbnailUrl,
  description,
  displayImportance,
  blocks,
  settings,
  tags,
  visibility,
  sceneTemplateKey,
  slideBackgroundColor
) => {
  const json = await sendRequest('/api/scene-template', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      {
        userId,
        sceneTemplateId,
        title,
        thumbnailUrl,
        description,
        displayImportance,
        blocks,
        settings,
        tags,
        visibility,
        sceneTemplateKey,
        slideBackgroundColor,
      },
      { userId, sceneTemplateId }
    ),
  });

  if (json.error) {
    return { error: 'Error updating scene template: ' + json.error };
  }
  return json.result;
};

export const deleteSceneTemplateApi = async (sceneTemplateId, userId) => {
  const json = await sendRequest(
    `/api/scene-template`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sceneTemplateId }),
    },
    { userId }
  );

  if (json.error) {
    return { error: 'Error deleting scene template: ' + json.error };
  }
  return json.result;
};

export const fetchStripeConfigApi = async () => {
  return await sendRequest('/api/stripe/config');
};

export const createStripeSubscriptionApi = async (
  userId,
  subscriptionItems,
  metadata = {}
) => {
  const json = await sendRequest(`/api/stripe/subscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, items: subscriptionItems, metadata }),
  });
  return json;
};

export const updateStripeSubscriptionApi = async (
  stripeSubscriptionId,
  subscriptionItems
) => {
  const json = await sendRequest(
    `/api/stripe/subscription/${stripeSubscriptionId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: subscriptionItems }),
    }
  );
  return json;
};

export const fetchStripeSubscriptionApi = async (stripeSubscriptionId) => {
  // TODO: Consider gating access to this method, or perhaps not expanding the payment method
  // unless the request is authenticated to the stripe customer associated with the subscription.

  const json = await sendRequest(
    `/api/stripe/subscription?subscriptionId=${encodeURIComponent(
      stripeSubscriptionId
    )}`
  );
  return json.result;
};

export const rsvpUser = async (
  userName,
  emailAddress,
  organization,
  meetingSeriesId,
  scheduledEvent,
  calendarEvent,
  meetingSeriesTitle,
  userTitle,
  phone,
  question
) => {
  const searchParams = new URLSearchParams(window.location.search);

  const utmSource = searchParams.get('utm_source') || undefined;
  const utmMedium = searchParams.get('utm_medium') || undefined;
  const utmCampaign = searchParams.get('utm_campaign') || undefined;
  const utmContent = searchParams.get('utm_content') || undefined;
  const utmTerm = searchParams.get('utm_term') || undefined;

  const urlParams = searchParams.toString();

  const json = await postJson(
    `/api/user/rsvp`,
    {
      userName,
      emailAddress,
      organization,
      meetingSeriesId,
      scheduledEvent,
      calendarEvent,
      meetingSeriesTitle,
      userTitle,
      phone,
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
      utmTerm,
      urlParams,
      question,
    },
    { meetingSeriesId }
  );

  if (json.error) {
    return { error: json.error };
  }

  return json.result;
};

export const sendSpeakerInvite = async (
  meetingSeriesId,
  speakerEmailAddress
) => {
  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/inviteSpeaker`,
    {
      emailAddress: speakerEmailAddress,
    },
    { meetingSeriesId }
  );

  if (json.error) {
    return { error: json.error };
  }

  return json.result;
};

export const sendHostInvite = async (meetingSeriesId, hostEmailAddress) => {
  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/inviteHost`,
    {
      emailAddress: hostEmailAddress,
    },
    { meetingSeriesId }
  );

  if (json.error) {
    return { error: json.error };
  }

  return json.result;
};

export const getMeetingChatTranscript = async (meetingId) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/chat-transcript`
  );

  if (json.error) {
    logerror({
      meetingId,
      message: json.error,
    });
    return [];
  }
  return json.result;
};

export const updateSocialMediaPostProperty = async (
  highlightId,
  value,
  meetingId,
  socialMediaType,
  property
) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/update-social-media-post-property`,
    { highlightId, value, socialMediaType, property },
    { highlightId, meetingId }
  );

  if (json.error) {
    logerror({
      meetingId,
      highlightId,
      message: json.error,
    });

    return { error: true };
  }

  return json.result;
};

export const regenerateSocialMediaPost = async (
  highlightId,
  meetingId,
  socialMediaType,
  transcript
) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/regenerate-social-media-post`,
    { highlightId, socialMediaType, transcript },
    { highlightId, meetingId }
  );

  if (json.error) {
    logerror({
      meetingId,
      highlightId,
      socialMediaType,
      message: json.error,
    });

    return { error: true };
  }

  return json.result;
};

export const fetchMeetingVoiceTranscriptWithTimeInterval = async (
  meetingId,
  startTime,
  endTime
) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/voice-transcript-time-interval?startTime=${encodeURIComponent(
      startTime
    )}&endTime=${encodeURIComponent(endTime)}`
  );

  if (json.error) {
    logerror({
      meetingId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const fetchMeetingVoiceTranscript = async (meetingId) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/voice-transcript`
  );

  if (json.error) {
    logerror({
      meetingId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const createSocialMediaAsset = async (
  meetingId,
  highlightId,
  mediaType,
  audioFileUrl,
  socialMediaType,
  logoDimensions
) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/create-social-media-asset-v2`,
    { highlightId, mediaType, audioFileUrl, socialMediaType, logoDimensions },
    { highlightId, meetingId }
  );

  if (json.error) {
    logerror({
      meetingId,
      highlightId,
      message: json.error,
    });

    return { error: true };
  }

  return json.result;
};

export const deleteHighlightApi = async (meetingId, highlightId) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/delete-highlight`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ highlightId }),
    },
    { meetingId, highlightId }
  );

  if (json.error) {
    logerror({
      meetingId,
      highlightId,
      message: 'Error deleting highlight: ' + json.error,
    });
    return {};
  }
  return json.result;
};

export const deleteMarketingAssetApi = async (
  meetingId,
  highlightId,
  assetId
) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/delete-marketing-asset`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ highlightId, assetId }),
    },
    { meetingId, highlightId, assetId }
  );

  if (json.error) {
    logerror({
      meetingId,
      highlightId,
      assetId,
      message: 'Error deleting marketing asset: ' + json.error,
    });
    return { error: true };
  }
  return json.result;
};

export const generateTextApi = async (prompt, userId) => {
  const json = await postJson(`/api/meetingseries/generate-text`, { prompt });

  if (json.error) {
    logerror({
      userId,
      message: json.error,
    });

    return { error: true };
  }

  return json.result;
};

export const copyMeetingToAnotherSeriesApi = async (
  meetingSeriesId,
  meetingId
) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/copy-meeting-into-series`,
    {
      meetingSeriesId,
    },
    { meetingSeriesId, meetingId }
  );

  if (json.error) {
    logerror({
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const generatePromoteEventTextApi = async (
  meetingSeriesId,
  fieldsToUpdate,
  prompt
) => {
  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/generate-promote-event-text`,
    {
      fieldsToUpdate,
      prompt,
    },
    {
      meetingSeriesId,
      fieldsToUpdate,
      prompt,
    }
  );

  if (json.error) {
    logerror({
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const generateEventYoutubeDescription = async (
  meetingId,
  meetingSeriesId
) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/generate-youtube-description`,
    { meetingSeriesId },
    { meetingId, meetingSeriesId }
  );

  if (json.error) {
    logerror({
      meetingId,
      meetingSeriesId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const getMeetingEventsApi = async (meetingId) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/meeting-events`,
    null,
    { meetingId }
  );

  if (json.error) {
    logerror({
      meetingId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const generateEventPosterInBackgroundApi = async (meetingSeriesId) => {
  const json = await postJson(
    `/api/image-generation/event-poster`,
    { meetingSeriesId },
    { meetingSeriesId }
  );

  if (json.error) {
    logerror({
      meetingSeriesId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const startPostRecordingActionsApi = async (
  meetingSeriesId,
  meetingId
) => {
  const json = await postJson(
    `/api/meeting-v1/${meetingId}/start-post-recording-actions`,
    {},
    { meetingSeriesId, meetingId }
  );

  if (json.error) {
    logerror({
      meetingSeriesId,
      meetingId,
      message: json.error,
    });
    return { error: true };
  }
  return json.result;
};

export const getSpeakersByIdApi = async (speakerId, workspaceId) => {
  let url = `/api/speakers/${speakerId}`;

  if (workspaceId) {
    url += `?workspaceId=${workspaceId}`;
  }

  const json = await sendRequest(url, null, {});

  if (json.error) {
    logerror({
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};
export const getSpeakersByWorkspaceIdApi = async (workspaceId) => {
  const json = await sendRequest(
    `/api/speakers?` + new URLSearchParams({ workspaceId }).toString(),
    null,
    {
      workspaceId,
    }
  );

  if (json.error) {
    logerror({
      workspaceId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};
export const createNewSpeakerApi = async (workspaceId, speaker) => {
  const json = await postJson(
    `/api/speakers/`,
    { ...speaker, workspaceId },
    { workspaceId }
  );

  if (json.error) {
    logerror({
      workspaceId,
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const updateSpeakerApi = async (workspaceId, speaker) => {
  const speakerId = speaker._id;

  const json = await sendRequest(
    `/api/speakers/${speakerId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(speaker),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { workspaceId }
  );

  if (json.error) {
    logerror({
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const removeSpeakerApi = async (workspace, { _id: speakerId }) => {
  const json = await sendRequest(
    `/api/speakers/${speakerId}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ removedSpeakerId: speakerId }),
    },
    {}
  );

  if (json.error) {
    logerror({
      message: json.error,
    });
    return { error: true };
  }

  return json.result;
};

export const updateEpisodeSettingsApi = async (meetingSeriesId, settings) => {
  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/episode-settings`,
    settings,
    { meetingSeriesId }
  );

  return json.result;
};

export const updatePromotionKitSettingsApi = async (
  meetingSeriesId,
  settings
) => {
  const json = await postJson(
    `/api/meetingseries/${meetingSeriesId}/promotion-kit-settings`,
    settings,
    { meetingSeriesId }
  );

  return json.result;
};

export const fetchGoogleOAuthURL = async (workspaceId) => {
  const json = await sendRequest(
    `/auth/oauth/url?workspaceId=${encodeURIComponent(workspaceId)}`
  );

  if (json.error) {
    logerror({
      message: json.error,
      workspaceId,
    });

    return { error: true };
  }

  return json.result;
};

export const fetchYoutubeChannelsApi = async (accessToken, workspaceId) => {
  const json = await sendRequest(
    `https://www.googleapis.com/youtube/v3/channels?access_token=${accessToken}&part=snippet&mine=true`
  );

  if (json.error) {
    return { error: json.error.status };
  }

  return json;
};

export const exchangeCodeForRefreshAndAccessTokenApi = async (
  code,
  workspaceId
) => {
  const json = await postJson(`/auth/oauth/google/get-tokens`, {
    code,
    workspaceId,
  });

  if (json.error) {
    return {
      error: json.error,
    };
  }

  return json.result;
};

export const refreshGoogleOAuthAccessTokenApi = async (
  refreshToken,
  workspaceId
) => {
  const json = await postJson(`/auth/oauth/google/refresh-access-tokens`, {
    refreshToken,
    workspaceId,
  });

  if (json.error) {
    return {
      error: json.error,
    };
  }

  return json.result;
};

export const revokeOAuthTokenApi = async (accessToken) => {
  const json = await postJson(
    `https://oauth2.googleapis.com/revoke?token=${accessToken}`
  );

  if (json.error) {
    return { error: json.error };
  }

  return json;
};

export const fetchWorkspaceIpAddress = async (workspaceId) => {
  const json = await sendRequest(
    `/api/infra/workspace-ip?name=ip-pages-${workspaceId}`
  );

  if (json.error) {
    return { error: 'Ip not found' };
  }

  return json.result;
};

export const fetchWorkspaceIpSslCertificate = async (workspaceId) => {
  const json = await sendRequest(
    `/api/infra/workspace-certificate?name=cert-pages-${workspaceId}`
  );

  if (json.error) {
    return { error: 'Certificate not found' };
  }

  return json.result;
};

export const provisionCustomerFacingEndpoint = async (domain, workspaceId) => {
  const json = await postJson(
    `/api/infra/provision-workspace`,
    { domain, workspaceId },
    {
      workspaceId,
    }
  );

  return json.result;
};

export const sendMessageToUserActivationsApi = async (action, data) => {
  const json = await postJson(
    '/api/slack/message/user-activations',
    {
      action,
      data,
    },
    { action, data }
  );

  return json.result;
};

export const inviteSpeakerToCalendlyEvent = async ({
  calendlyEventLink,
  speakerEmail,
  speakerName,
  showName,
  hostName,
}) => {
  const json = await postJson(
    '/api/calendly/invite',
    {
      calendlyEventLink,
      speakerEmail,
      speakerName,
      showName,
      hostName,
    },
    { calendlyEventLink, speakerEmail, speakerName, showName, hostName }
  );

  return json.result;
};

export const triggerPreparationKitApi = async (
  meetingSeriesId,
  workspaceId,
  userId
) => {
  const response = await postJson(`${PROCESSOR_BASE_URL}/cloudtask`, {
    taskDetails: {
      processorId: 'PREPARATION_KIT',
      meetingSeriesId,
      workspaceId,
    },
    forceRun: true,
  });

  if (response.error) {
    logerror({
      userId,
      message: response.error,
    });
    return {};
  }

  return response.result;
};

export const getPreparationKitApi = async (meetingSeriesId, workspaceId) => {
  const json = await sendRequest(
    `${PREPARATION_KIT_BASE_URL}?meetingSeriesId=${meetingSeriesId}&workspaceId=${workspaceId}`,
    null,
    {
      meetingSeriesId,
    }
  );
  return json.result;
};

export const addLiveStreamToMeeting = async (
  meetingId,
  { streamId, name, streamUrl, streamKey }
) => {
  const json = await sendRequest(`/api/meeting-v1/${meetingId}/live-stream`, {
    method: 'POST',
    headers: { 'content-Type': 'application/json' },
    body: JSON.stringify({ name, streamUrl, streamKey, streamId }),
  });

  return json.result;
};

export const updateMeetingLiveStream = async (meetingId, streamConfig) => {
  const json = await sendRequest(`/api/meeting-v1/${meetingId}/live-stream`, {
    method: 'PUT',
    headers: { 'content-Type': 'application/json' },
    body: JSON.stringify(streamConfig),
  });

  return json.result;
};

export const removeLiveStreamFromMeeting = async (meetingId, streamId) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/live-stream/${streamId}`,
    {
      method: 'DELETE',
    }
  );

  return json.result;
};

export const confirmMeetingLiveStreamSettings = async (meetingId) => {
  const json = await sendRequest(
    `/api/meeting-v1/${meetingId}/live-stream/confirm`,
    {
      method: 'POST',
      headers: { 'content-Type': 'application/json' },
    }
  );

  return json.result;
};

export const requestLinkedinAccessTokenApi = async (code) => {
  const json = await sendRequest(`/api/linkedin/accessToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
  });
  return json;
};

export const revokeLinkedinAccessTokenApi = async (workspaceId) => {
  const json = await sendRequest(`/api/linkedin/revokeAccessToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ workspaceId }),
  });
  return json;
};

export const getLinkedinTokenStatusApi = async (workspaceId, tokenType) => {
  const json = await sendRequest(
    `/api/linkedin/token-status?workspaceId=${workspaceId}&tokenType=${tokenType}`
  );
  return json?.result;
};

export const refreshLinkedinTokenApi = async (workspaceId) => {
  const json = await sendRequest(`/api/linkedin/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ workspaceId }),
  });
  return json;
};

export const getEpisodeAssets = async (meetingSeriesId) => {
  const json = await sendRequest(
    `/api/meetingseries/assets/${meetingSeriesId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return json;
};

export const getSoloNewEpisodeAssetApi = async (
  meetingSeriesId,
  workspaceId,
  userId
) => {
  const response = await sendRequest(
    `${CONTENT_KIT_ASSET_BASE_URL}?workspaceId=${workspaceId}&meetingSeriesId=${meetingSeriesId}&processorId=SOLO_NEW_SESSION_PROCESSOR`
  );

  if (response.error) {
    logerror({
      userId,
      message: response.error,
    });
    return {};
  }

  return response.result;
};

export const getGroupIdAssets = async (groupId) => {
  const response = await sendRequest(
    `${CONTENT_KIT_ASSETS_BASE_URL}?groupId=${groupId}`
  );

  if (response.error) {
    logerror({
      message: response.error,
    });
    return {};
  }

  return response.result;
};

export const triggerAiSoloEpisode = async ({
  meetingSeriesId,
  workspaceId,
  userId,
  blogPostText,
  newIdeaText,
  isQuickStart,
  topic,
  videoScript,
}) => {
  loginfo({
    message: `triggerAiSoloEpisode called`,
    workspaceId,
    meetingSeriesId,
    userId,
  });

  const response = await postJson(`/api/solomeetingseries`, {
    userId,
    meetingSeriesId,
    workspaceId,
    blogPostText,
    newIdeaText,
    isQuickStart,
    topic,
    videoScript,
  });

  if (response.error) {
    logerror({
      userId,
      message: response.error,
    });
    return {};
  }

  return response.result;
};

export const logIPAddressAPI = async (data) => {
  const json = await postJson('/api/logger/log-ip', data, data);
  return json.result;
};

export const resendEmailVerificationAPI = async ({
  redirectUrl,
  emailAddress,
}) => {
  const json = await postJson(
    `/auth/resend-email-verification`,
    { redirectUrl, emailAddress },
    { redirectUrl, emailAddress }
  );

  if (json.error) {
    logerror({
      message: `Error in resend email verification: ${JSON.stringify({
        redirectUrl,
        emailAddress,
      })}`,
    });
    return { error: true };
  }

  return json.result;
};

export const validateAppSumoLicense = async (authorizationCode) => {
  if (!authorizationCode) {
    return {
      error: 'We could not find your authorization code',
    };
  }

  const response = await sendRequest(
    `/api/app-sumo/validate-license?authorizationCode=${authorizationCode}`
  );

  if (response.error) {
    logwarn({
      message: response.error,
    });
    return {
      license: null,
      error: response.error,
    };
  }

  return { license: response.license, error: null };
};

export const checkIfUserAlreadySigned = async (userId) => {
  const response = await sendRequest(
    `/api/app-sumo/check-signed?userId=${encodeURIComponent(userId)}`
  );

  if (response.error) {
    logwarn({
      message: response.error,
    });
    return {
      result: null,
      error: response.error,
    };
  }

  return { result: response.result, error: null };
};

export const triggerAppSumoOnboarding = async (
  fullName,
  emailAddress,
  licenseKey
) => {
  if (!fullName || !emailAddress) {
    return {
      error: 'Please provide full name and email address',
    };
  }

  if (!licenseKey) {
    return {
      error:
        'License key not found. Please launch the product from AppSumo dashboard again.',
    };
  }

  const json = await postJson(`/api/quick-start-workspace`, {
    speaker: {
      email: emailAddress,
      name: fullName,
      about: '',
      contentPillars: [],
    },
    licenseKey,
  });

  if (json.error) {
    logwarn({
      message: json.error,
    });
    return {
      license: null,
      error: json.error,
    };
  }

  return json.result;
};

export const updateFeatureFlag = async (
  workspaceId,
  featureFlagKey,
  featureFlagValue
) => {
  const json = await postJson('/api/workspace/featureFlags', {
    featureFlagKey,
    featureFlagValue,
    workspaceId,
  });

  return json.result;
};

export const renderVideoEditorApi = async ({
  screenplay,
  meetingSeriesId,
  workspaceId,
  groupId,
}) => {
  try {
    const response = await axios.post('/api/video-editor/render', {
      screenplay,
      meetingSeriesId,
      workspaceId,
      groupId,
    });

    return response.data;
  } catch (error) {
    return { error: true };
  }
};

export const sendInviteToResponderApi = async ({
  zyncUpId,
  responderName,
  emailAddress,
  posterUrl,
  responderImageUrl,
  seekerName,
  topic,
}) => {
  try {
    const json = await postJson('/api/zync-up/send-email-to-responder', {
      zyncUpId,
      responderName,
      emailAddress,
      posterUrl,
      responderImageUrl,
      seekerName,
      topic,
    });

    if (json.error) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return { error: true };
  }
};

export const createResponderSessionApi = async ({
  seekerDetails,
  responderDetails,
}) => {
  try {
    const response = await axios.post('/api/zync-up/create-responder-session', {
      seekerDetails,
      responderDetails,
    });

    if (response?.data?.error) {
      return null;
    }

    return response?.data?.result;
  } catch (error) {
    return null;
  }
};

export const getCreatorWorkspaces = async (emailAddress) => {
  try {
    const response = await axios.get(
      `/api/user/creator-workspaces?emailAddress=${encodeURIComponent(
        emailAddress
      )}&extended=true`
    );

    if (response?.data?.error) {
      return [];
    }

    return response?.data?.result || [];
  } catch (error) {
    return [];
  }
};

export const getOrganizationApi = async (organizationId) => {
  try {
    const response = await axios.get(`/api/organization/${organizationId}`);

    if (response?.data?.error) {
      return null;
    }

    return response?.data?.result;
  } catch (error) {
    return null;
  }
};

export const getOrganizationByDomain = async (organizationDomain) => {
  try {
    const response = await axios.get(
      `/api/organization/${organizationDomain}?domain=true`
    );

    if (response?.data?.error) {
      return null;
    }

    return response?.data?.result;
  } catch (error) {
    return null;
  }
};

export const fetchCreatorWorkspacesApi = async ({ emailAddress }) => {
  const json = await sendRequest(
    `/api/user/creator-workspaces?emailAddress=${encodeURIComponent(
      emailAddress
    )}`
  );

  if (json.error) {
    return null;
  }

  return json.result;
};
